// -------------------------------------------------------------------
// :: TABLES - #TBD
// -------------------------------------------------------------------

table {
	border-spacing: 0;
	margin: 0;

	border-collapse: collapse;
	//background: rgba($black, 0.01);
	//border-bottom: 1px solid $border-color;
}

// Dropdown menus and checkboxes in tables
// shouldn't have additional margin values

table .input,
table [class*='input__'] {
	margin: 0;
}

table select {
	font-weight: 400;
	border-color: transparent !important;
}

table select + [class*='icon'] {
	padding-top: 0.15em;
}

// -------------------------------------------------------------------
// :: TYPOGRAPHIC TABLE SCALE
// -------------------------------------------------------------------
// Table padding should follow a typographic scale
// - default height: 52px @ $base-font-size of 16px
// - default height: 50px @ $base-font-size of 15px

th:not(.has-no-padding) {
	&:first-child {
		padding-left: 0;
	}
}

th:not(.has-no-padding),
td:not(.has-no-padding) {
	padding: rem(16px) rem(18px);
}

// -------------------------------------------------------------------
// :: TABLE HEADERS
// -------------------------------------------------------------------

th:not(.is-empty) {
	font-size: rem($font-size-small);
	font-weight: 100;
	text-align: left;
	text-transform: uppercase;
	vertical-align: bottom;

	.input select {
		background-color: transparent;
		border: none;
		box-shadow: none;

		+ [class*='icon'],
		&.has-no-value + [class*='icon'] {
			font-size: 1.5em;
			margin: 0.13rem 0.5rem;
		}
	}
}

// -------------------------------------------------------------------
// :: TABLE DATA
// -------------------------------------------------------------------

tbody > tr {
	border: 1px solid $border-color-grey;
}

tbody > tr:nth-child(odd) {
	background: $background-color-regular;
}

td {
	vertical-align: top;
	position: relative;

	font-size: rem(14px);
	line-height: rem(16px);

	.input {
		label {
			width: 40%;
			float: left;
			padding: 0.6rem 0 0.4rem;
		}

		div {
			width: 59%;
			float: right;
		}

		&.text-right input {
			text-align: right;
		}

		&.text-primary-color input {
			color: $primary-color;
		}
	}
}

// -------------------------------------------------------------------
// :: TABLE MODIFIERS
// -------------------------------------------------------------------
.c-table__cell-weight-bold {
	font-weight: 500;
}

.c-table__cell--fill {
	min-width: rem(300px);
}
