@import '@cjm/styles/base/_globals';

// -------------------------------------------------------------------
// :: Animations
// -------------------------------------------------------------------

// breathing in-out animation.
// Used in:
//   * preloader

@keyframes breathe {
	0% {
		opacity: 0.1;
	}
	32% {
		opacity: 1;
	}
	//95%	{ opacity: 0.1; }
	100% {
		opacity: 0.1;
	}
}

// growing border animation
// Used in:
//  * inline-loader

@keyframes growing-border {
	0% {
		transform: scale(0);
		border-color: $primary-color;
		border-width: $loader-dot-size * 0.5;
	}

	43% {
		transform: scale(1);
		border-color: $primary-color;
	}

	80% {
		border-width: 1px;
		border-color: transparent;
		//opacity: 0;
	}

	100% {
		border-width: 0;
		border-color: transparent;
	}
}

// heartbeat animation
// Used in:
//  * primary-button

@keyframes heartbeat {
	0% {
		font-size: rem(24px);
	}
	60% {
		font-size: rem(26px);
	}
	100% {
		font-size: rem(24px);
	}
}
