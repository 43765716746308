// -------------------------------------------------------------------
// :: TYPOGRAPHY
// -------------------------------------------------------------------
// - https://github.com/railsware/applepie
// - http://www.google.com/design/spec/style/typography.html
// - http://typecast.com/blog/a-more-modern-scale-for-web-typography
// - http://meyerweb.com/eric/thoughts/2006/02/08/unitless-line-heights
// - http://modularscale.com

@import url('https://fonts.googleapis.com/css?family=Droid+Sans+Mono&display=swap');

@include get-font('Flanders Art Sans Light - CJM', '/assets/fonts/FlandersArtSans-Light', 300);
@include get-font('Flanders Art Sans - CJM', '/assets/fonts/FlandersArtSans-Regular');
@include get-font('Flanders Art Sans Medium - CJM', '/assets/fonts/FlandersArtSans-Medium', 500);
@include get-font('Flanders Art Sans Bold - CJM', '/assets/fonts/FlandersArtSans-Bold', 700);

@include get-font('Flanders Art Serif Pro - CJM', '/assets/fonts/FlandersArtSerifPro-Regular');
@include get-font('Flanders Art Serif Pro Bold - CJM', '/assets/fonts/FlandersArtSerifPro-Bold', 500);

// -------------------------------------------------------------------
// :: BASE
// -------------------------------------------------------------------

html {
	font-size: rem(16px);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	@if $debug-mode {
		@include respond-at($tablet) {
			font-size: rem(16px);
		}

		@include respond-at($desktop) {
			font-size: rem(16px);
		}

		@include respond-at($desktop-wide) {
			font-size: rem(16px);
		}
	}
}

body {
	color: $text-color-black;
	font-family: $font-family-sans;
	font-size: rem($font-size);
	line-height: $line-height;
}

html,
body {
	height: 100%;
	p {
		font-size: $font-size;
		font-family: $font-family-sans;
	}
	* {
		user-select: text;
	}

	h1 {
		font-size: 22px;
		font-family: $font-family-medium;
	}

	h2,
	h3,
	h4,
	h5,
	h6 {
		font-size: 18px;
		font-weight: normal;
		font-family: $font-family-medium;
	}
}

// -------------------------------------------------------------------
// :: NORMALIZE
// -------------------------------------------------------------------
// If a margin/padding is set somewhere, remove it from below
// Keep it DRY and prevent overwrites

h1,
h2,
h3,
h4,
h5,
h6 {
	color: $text-color-black-title;
	hyphens: auto;
	hyphenate-limit-chars: 8;
}

h1,
h2,
h4,
blockquote {
	font-family: $font-family-serif;
}

h1,
h2,
h3 {
	small {
		font-weight: 400;
		margin-left: 0.8rem;
	}
}

h4,
h5 {
	font-family: $font-family-sans;
	line-height: $line-height;
	font-weight: bold;
}

small {
	display: inline-block;
}

// For the H1: the overline-ornament is only showed when
// the class .h1 is added. To prevent crossover issues.
h1,
.h1 {
	font-size: rem($font-size-xlarge);
	line-height: 1.1875;
	font-weight: 500;
	margin-bottom: 2rem;

	@include respond-at($tablet) {
		font-size: rem($font-size-xxlarge);
	}
}

.h1 {
	padding-top: 10px;
	border-top: 5px solid $grey-light;

	// display: table to make the 100% of the :before equal to the
	// width of the text without loosing display block characteristics

	display: table;
}

h2 {
	font-size: rem($font-size-xlarge);
	font-weight: normal;
	line-height: 1.194;
}

h3 {
	font-size: rem($font-size-large);
	line-height: 1.23;
	margin-bottom: 1rem;

	@include respond-at($tablet) {
		font-size: rem($font-size-xlarge);
	}
}

h4,
.h4 {
	font-size: rem($font-size-medium);
	line-height: 1.5;

	@include respond-at($tablet) {
		font-size: rem($font-size-large);
	}

	+ p {
		margin-top: 0.5rem;
	}
}

h5 {
	font-size: rem($font-size);
	line-height: 1.25;
	margin-bottom: 0.5rem;

	+ p {
		margin-top: 0;
	}
}

h6 {
	font-size: 1.1rem;
	margin-top: 0.9rem;
}

p {
	font-size: rem($font-size);
	margin: 1.2em 0;
	color: $text-color-black;

	&:first-child {
		margin: 0;
	}

	[class*='icon'] {
		line-height: 1;

		&:before {
			font-size: 2.5em;
		}
	}
}

p + {
	h1,
	h2,
	h3 {
		margin-top: 3rem;
	}

	h4,
	h5 {
		margin-top: 2rem;
	}
}

// -------------------------------------------------------------------
// :: MARGINS
// -------------------------------------------------------------------
// If a margin/padding is set somewhere, remove it from below
// Keep it DRY and prevent overwrites

h1,
h2,
h3,
h4,
p,
h5,
h6,
ul,
ol,
body {
	margin: 0;
}

// -------------------------------------------------------------------
// :: BLOCK ELEMENTS
// -------------------------------------------------------------------

small,
cite {
	font-size: rem($font-size-small);
}

.primary-color {
	color: $primary-color !important;
}

.white-color {
	color: $text-color-light !important;
}

.ui-color-orange {
	color: $ui-orange;
}

.ui-color-red {
	color: $ui-red;
}

// -------------------------------------------------------------------
// :: DECORATORS
// -------------------------------------------------------------------

// Hyperlinks: the hover effect with the :after element is only active
// when the class .is-hyperlink is added to the element or when the
// a-tag is inside a paragraph - This to prevent unwanted after
// elements when it is not needed (example grid links)

.is-hyperlink,
a {
	color: $link-color;
	background: transparent;
	cursor: pointer;
	transition: color $animation-fast;
	text-decoration: $link-underline;

	&:hover,
	&:active,
	&:focus {
		outline: $focus-outline;
		outline-offset: 1px;
	}
}

.u-link--bold {
	font-weight: 700;
}

.u-link--no-decoration {
	text-decoration: none;

	&:hover,
	&:focus {
		text-decoration: underline !important;
	}
}

.u-link__underline {
	&:hover,
	&:active {
		outline: none;

		text-decoration: underline;

		color: $link-color-dark;
	}
}

.u-link__external {
	span {
		text-decoration: inherit;
	}

	&:after {
		content: '\f08e';
		font: var(--fa-font-solid);
		font-size: rem(12px);
		padding-left: rem(6px);
	}
}

.a--color-black {
	color: $black;
}

// Highlight text-selection
// And adjust font-color for
// deleted/updated content

//mark {
//  background: $ui-yellow;
//}

del {
	color: $font-color-medium;
}

// A better looking horizontal ruler
// Inspired by the HTML5 Boilerplate
// http://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css

hr {
	border: none;
	border-top: 1px solid $border-color;
	box-sizing: content-box;
	margin: 1.75em 0;
	//display: block;
	//padding: 0;
	//height: 0;
}

hr.extra-top-margin {
	margin-top: 4em;
}

// Blockquote and
// author name (cite)

blockquote {
	font-weight: 100;
	font-size: rem(26px);
	line-height: 1.25;
	margin: 0;
}

cite {
	color: $font-color-medium;
	font-family: $font-family-sans;
	font-style: normal;
	margin-top: 0.75em;
	display: block;
	border-radius: $border-radius;
}

cite:before {
	content: '\2014';
	margin: 0 0.3em;
}

code {
	text-transform: none;
	background: $grey-light;
	padding: 0.2rem;
	border: $border-simple;
	border-radius: $border-radius;
}

// Label
label[for] {
	cursor: pointer;
}

small[class*='icon-'] {
	position: relative;

	&:before {
		position: absolute;
		left: -2rem;
		font-size: $font-size-large;
		color: $primary-color-dark;
		top: 50%;
		transform: translateY(-50%);
	}
}

// -------------------------------------------------------------------
// :: FONT WEIGHTS
// -------------------------------------------------------------------

.u-font-bold {
	font-family: $font-bold-font-family;
	font-weight: $font-bold-font-weight !important;
}

/**
* The following classes allow for easy decorations of text.
*/
.c-vloket-text-marking {
	background-repeat: no-repeat;

	word-break: break-word; // This is deprecated as stated here: https://developer.mozilla.org/en-US/docs/Web/CSS/word-break
	overflow-wrap: anywhere; // This is the replacement, but is not yet fully supported as stated here: https://developer.mozilla.org/en-US/docs/Web/CSS/overflow-wrap

	&--underline {
		background-image: linear-gradient(transparent, transparent), linear-gradient($primary-color, $primary-color);
		background-size:
			0,
			100% rem(4px);
		background-position-y: bottom;
	}

	&--line-through {
		background-image: linear-gradient(transparent, transparent), linear-gradient($primary-color, $primary-color);
		background-size:
			0,
			100% 24%;
		background-position: left 0 bottom 12%;
	}
}

/**
 * The following classes provide styling for the headers within the application.
 */
.c-vloket-header {
	font-family: $font-family-medium;

	&--h1 {
		font-size: rem(60px);
		margin-bottom: $layout-spacing;

		@include respond-to($desktop) {
			font-size: rem(44px);
		}

		@include respond-to($tablet) {
			font-size: rem(36px);
		}
	}

	&--h2 {
		font-size: rem(44px);
		margin-bottom: rem(30px);

		@include respond-to($desktop) {
			font-size: rem(32px);
		}
	}

	&--h3 {
		font-size: rem(32px);
		margin-bottom: $layout-spacing-s;

		@include respond-to($desktop) {
			font-size: rem(26px);
		}
	}

	&--h4 {
		font-size: rem(26px);
		margin-bottom: $layout-spacing-s;

		@include respond-to($desktop) {
			font-size: rem(22px);
		}
	}

	&--h5 {
		font-size: rem(22px);
		margin-bottom: $layout-spacing-s;

		@include respond-to($desktop) {
			font-size: rem(20px);
		}
	}

	&--h6 {
		font-size: rem(20px);
		margin-bottom: $layout-spacing-s;

		@include respond-to($desktop) {
			font-size: rem(18px);
		}
	}
}

.c-vloket-header--subtitle {
	font-family: $font-family-medium;
	font-size: rem(20px);
}

/**
 * The following class provides styling for descriptive blocks of text.
 */
.c-vloket-text {
	&--s {
		font-size: rem(16px);
	}

	&--lg {
		font-size: rem(22px);
		line-height: rem(30px);

		p:not(:last-child) {
			margin-bottom: rem(20px);
		}

		@include respond-to($desktop) {
			font-size: rem(18px);
		}
	}

	&--light {
		color: $text-color-blue-grey;
	}

	&--extra-light {
		color: $text-color-blue-grey-light;
	}

	&--single-line-clip {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}

/**
 * Provide styling for HTML elements
 */
strong {
	font-weight: bold;
	letter-spacing: 0.5px;
}

/**
 * Status based text highlights
 */

.is-success {
	color: $ui-green;
}

.is-warning {
	color: $ui-orange;
}

.is-error {
	color: $ui-error;
}
