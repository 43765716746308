.c-sign-up {
	@include zigzag;

	text-align: left;
}

.c-sign-up__card {
	position: relative;
	padding: rem(36px);

	background-color: $white;

	@include respond-at($desktop) {
		padding: rem(56px) rem(140px);
	}
}

.c-sign-up__image-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.c-sign-up__image {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0;

	background-color: $white;

	img {
		max-width: 100%;
		height: auto;
		vertical-align: center;
		border: none;
	}
}
