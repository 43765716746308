// -------------------------------------------------------------------
// :: GLOBAL LAYOUT
// -------------------------------------------------------------------

html {
	overflow-x: hidden;

	&.has-filter-open {
		overflow: hidden;
	}
}

body {
	padding-top: $header-menu-height;
	padding-bottom: 65px;

	@include respond-at($tablet) {
		padding-bottom: 0;
	}

	@media all and (max-width: $mobile) {
		max-height: 100vh;
	}
}

.u-overflow-y--hidden--vp9 {
	@include respond-to($desktop) {
		overflow-y: hidden;
	}
}

.u-overflow-y--hidden--vp12 {
	@include respond-to($desktop-wide-extended) {
		overflow-y: hidden;
	}
}

.u-overflow-y--hidden {
	overflow-y: hidden;
}

.u-display-none {
	display: none;
}

button,
a {
	cursor: pointer;
}

.menu-offset {
	margin: 0;
	padding-top: $header-menu-height;
}

.u-wrapper {
	@extend %__wrapper;
	position: relative;
}

%__wrapper {
	width: 100%;
	max-width: $max-width;
	margin-left: auto;
	margin-right: auto;
	padding-left: $wrapper-padding * 0.5;
	padding-right: $wrapper-padding * 0.5;

	@include respond-at($tablet) {
		padding-left: $wrapper-padding;
		padding-right: $wrapper-padding;
	}
}

::selection {
	background: $selection-color;
}

::-moz-selection {
	background: $selection-color;
}

[placeholder] {
	text-overflow: ellipsis;
}

// Utility classes for
// responsive behaviour

.u-outline {
	&:focus-visible,
	&:focus {
		outline: $focus-outline;
		outline-offset: 1px;
	}
}

// -----------------------------------------------------------------------------
// :: Utils
// -----------------------------------------------------------------------------
.u-border-stripes {
	display: inline-block;
	position: relative;
	padding-bottom: 1rem;
	margin-bottom: 1.5rem;

	&:after {
		@include raster-bottom;
	}
}

.c-container {
	margin-left: $container-margin;
	margin-right: $container-margin;
	max-width: rem($container-max-width);

	@include respond-at($desktop) {
		margin-left: $container-margin-l;
		margin-right: $container-margin-l;
	}

	@include respond-at($desktop-ultra-wide) {
		margin-left: $container-margin-xl;
		margin-right: $container-margin-xl;
	}

	@include respond-at($desktop-2k) {
		margin-left: $container-margin;
		margin-right: $container-margin;
	}
}

.u-reset-list {
	margin: 0;
	padding: 0;

	list-style-type: none;
}

.u-flex {
	display: flex;
}

// -----------------------------------------------------------------------------
// :: Width
// -----------------------------------------------------------------------------
.u-width-100 {
	width: 100%;
}

.u-width-75 {
	width: 75%;
}

.u-width-50 {
	width: 50%;
}

.u-width-25 {
	width: 25%;
}
