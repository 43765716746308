// -------------------------------------------------------------------
// :: NAVIGATION
// -------------------------------------------------------------------

.topbar {
	position: fixed;
	// Denis: The material CDK overlay container has a z-index of 1000, set this 2 lower
	z-index: $z-index-sky - 2;
	top: 0;
	left: 0;
	width: 100%;
	background-color: $white;
	border-bottom: 1px solid $white-93;

	&.menu-is-open {
		z-index: $z-index-sky + 2;
	}

	// transition: 0.3s ease-in-out;
	// transform: translateY(0%);

	.topbar__info-wrapper {
		font-family: $font-family-primary-medium;
		height: $header-height;

		.flanders {
			margin-left: 18px;
			font-size: 19px;
		}

		.title {
			margin-left: 18px;
			font-weight: 500;
			font-size: 14px;

			@include respond-at($tablet-wide) {
				font-size: 16px;
			}

			a {
				color: inherit;
				outline: none;
			}
		}
	}

	.main-navigation {
		.main-navigation__item {
			a {
				color: $black-title;

				&:hover,
				&.is-active {
					color: $primary-color;
				}
			}

			@include respond-at($tablet-wide) {
				&:last-child {
					a {
						&:hover {
							color: $white;
						}
					}
				}
			}
		}
	}

	.toggle {
		position: fixed;
		top: 0;
		right: 0;
		z-index: 1100;
		width: $header-height;
		height: $header-height;
		outline: none;
		border: none;
		opacity: 0;

		@include respond-at($tablet-wide) {
			display: none;
		}
	}

	.toggle-icon {
		position: fixed;
		top: 10px;
		right: 10px;
		width: 24px;
		height: 24px;
		z-index: 1010;
		background-color: $white;

		&:before,
		&:after {
			content: '';
		}

		&:before,
		&:after,
		span {
			margin-top: 3px;
			width: 24px;
			height: 4px;
			display: block;
			background-color: $primary-color;
			transition: all 165ms ease-in-out;
		}

		@include respond-at($tablet-wide) {
			display: none;
		}
	}

	.toggle:checked ~ .toggle-icon {
		span {
			opacity: 0;
			transform: scale(0);
		}

		&:before {
			transform: rotate(45deg);
			margin-top: 12px;
			margin-bottom: -13px;
		}

		&:after {
			transform: rotate(-45deg);
		}
	}

	.toggle:checked ~ .main-navigation {
		opacity: 1;
		height: 100vh;
		transform: scale(1);
	}

	.toggle ~ .main-navigation {
		opacity: 0;
		height: 0;
		transform: scale(0.7);

		@include respond-at($tablet-wide) {
			opacity: 1;
			height: 100%;
			transform: scale(1);
		}
	}

	.main-navigation {
		display: block;
		padding: 0;
		width: 100%;
		height: 100%;
		position: fixed;
		z-index: 700;
		top: 0;
		left: 0;
		margin: 0;
		transform-origin: top right;
		background-color: $white;
		overflow: auto;
		transition: all 165ms ease-in-out;
		transition-delay: 50ms;

		.main-navigation__item {
			list-style: none;
			width: 100%;

			a {
				display: block;
				padding: 1rem 2.5rem;

				@include respond-at($tablet-wide) {
					padding: 0 1rem;
				}

				[class^='icon-'] {
					margin-right: 0.5rem;
					font-size: 26px;
				}
			}

			.main-navigation__flyout-menu ul {
				list-style: none;
				padding: 0;

				li {
					a {
						border: none;
					}
				}
			}
		}

		@include respond-at($tablet-wide) {
			position: inherit;
			transform-origin: inherit;
			background-color: transparent;
			overflow: visible;
			transition-duration: 0s;

			.main-navigation__item {
				list-style-image: none;
				list-style-position: outside;
				list-style-type: none;
				width: auto;

				> a {
					display: inline;
					padding: 0;
				}
			}
		}
	}

	@include respond-at($tablet-wide) {
		.topbar__navigation-wrapper {
			float: right;
		}

		.topbar__info-wrapper {
			float: left;
		}

		.toggle {
			display: none;
		}

		.main-navigation {
			.main-navigation__item {
				> a {
					font-size: 14px;
					font-family: $font-family-primary-medium;
					text-decoration: none;
				}
				position: relative;
				line-height: $header-height;
				display: inline-block;
				padding-left: 0.8rem;
				padding-right: 0.8rem;
			}

			> li:last-child {
				background-color: $primary-color;
				margin-left: 18px;
				padding-left: 18px;

				a  {
					color: $white;
				}

				&:before {
					content: '';
					width: 18px;
					height: 100%;
					position: absolute;
					top: 0;
					left: -18px;
					border-top: $header-height solid $primary-color;
					border-left: 18px solid transparent;
				}
			}
		}
	}
}

.search-input-mini {
	transition: all 330ms ease-in-out;
	position: relative;
	display: inline-block;
	vertical-align: top;

	&.is-invisible {
		opacity: 0;
		transform: translate3d(0, -55px, 0);
	}

	form {
		border-right: 1px solid $white-93;
		height: 55px;
		margin-left: 18px;
		padding: 4px 0.5rem;
		transform: skew(18deg, 0deg);
		background-color: $white;

		input {
			transform: skew(-18deg, 0deg);
			background: none;
			border: none;
			box-shadow: none;
			width: 0;
			max-width: 200px;
			padding: 0;
			transition: width 330ms ease-in-out;

			@include respond-at($desktop) {
				width: 120px;
			}

			&:focus {
				width: 35vw;

				+ button {
					display: inline-block;
				}

				+ button + label {
					display: none;
				}

				@include respond-at($tablet-wide) {
					width: 23vw;
				}

				@include respond-at($desktop) {
					width: 200px;
				}
			}
		}

		.c-button {
			transform: skew(-18deg, 0deg);
			padding: 0 1rem;
			background: none;
			font-size: 20px;
			line-height: 0;
			color: $white-98;

			&:hover {
				background: none !important;
				color: $white-93;
			}
		}

		button {
			display: none;
		}

		label {
			padding-top: 12px !important;
		}
	}
}

.scroll-progress {
	position: absolute;
	bottom: 0;
	left: 0;
	height: 3px;
	background: $primary-color;
}

// -------------------------------------------------------------------
// :: NAVIGATION FLYOUT MENU
// -------------------------------------------------------------------

.main-navigation__item:hover .main-navigation__flyout-menu {
	height: auto;
	pointer-events: auto;

	ul {
		opacity: 1;
		transform: none;
	}

	hr {
		margin: 0.5rem 0;
	}
}

.main-navigation__flyout-menu {
	ul {
		background-color: $white;
		border: 1px solid $white-93;
		box-shadow: 0 1px 3px 0 rgba($black, 0.1);

		@include respond-at($tablet-wide) {
			box-shadow: 0 1px 3px 0 rgba($black, 0.1);
		}
	}
}

@include respond-at($tablet-wide) {
	.main-navigation__flyout-menu {
		position: absolute;
		min-width: 245px;
		top: 2rem;
		padding-top: 1.5rem;
		left: rem(-40px);
		z-index: 10000;

		ul {
			&:before {
				@include FLOW-css-triangle-with-border('up', 0.8em, $_fill: $white, $_border-color: $white-93);
				position: absolute;
				left: calc(50% - 0.4em);
			}

			position: relative;
			list-style-type: none;
			padding: 0;
		}

		li {
			color: $grey-46;
			cursor: pointer;

			&:not(:last-child) {
				border-bottom: 1px solid $white-93;
			}

			&.is-disabled {
				cursor: not-allowed;
				color: rgba($black, 0.5);

				a {
					pointer-events: none;
				}
			}
		}

		a,
		button {
			color: inherit;
			cursor: inherit;
			padding: 0.5rem 1rem;
			display: block;

			[class^='icon-'] {
				margin-right: 0.5rem;
				font-size: 26px;
			}

			&:hover,
			&.is-active {
				color: $primary-color;
			}

			.c-info-tooltip {
				top: 0.1rem;
				left: 0.5rem;
				pointer-events: all;
			}
		}
	}
}

// -------------------------------------------------------------------
// :: NAVIGATION CONTACT MENU
// -------------------------------------------------------------------

nav .m-contact {
	position: relative;

	@include respond-at($tablet-wide) {
		position: absolute;
		top: 2.5rem;
		right: 1.5rem;
		z-index: 100;
	}
}
