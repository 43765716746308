// -------------------------------------------------------------------
// :: GLOBAL SETTINGS
// -------------------------------------------------------------------
// Debug breakpoints
// - see utils/_media-queries.scss

$debug-mode: false;

// -------------------------------------------------------------------
// :: MEDIA QUERIES
// -------------------------------------------------------------------
// Major breakpoints should be set in base/_typography.scss
// Minor breakpoint should be added where needed (inline)
$mobile-small: 360px;
$mobile: 480px;
$tablet: 680px;
$tablet-wide: 800px;
$desktop: 1056px;
$desktop-wide: 1140px;
$desktop-wide-extended: 1312px;
$desktop-ultra-wide: 1600px;
$desktop-2k: 2048px;
$desktop-2k-extended: 2560px;

// -------------------------------------------------------------------
// :: COLORS
// -------------------------------------------------------------------
@import 'utils/_functions.scss';
@import '_quarks/colors';

// -------------------------------------------------------------------
// :: FONT-SIZES
// -------------------------------------------------------------------
// Base-font-sizes should be set in base/_typography.scss
// - http://www.awayback.com/revised-font-stack
// - http://practicaltypography.com/system-fonts.html

$font-family-sans: 'Flanders Art Sans - CJM', sans-serif;
$font-family-serif: 'Flanders Art Serif Pro - CJM', serif;
$font-family-mono: 'Droid Sans Mono - CJM', monospace;
$font-family-medium: 'Flanders Art Sans Medium - CJM', 'Arial', sans-serif;
$font-family-bold: 'Flanders Art Sans Bold - CJM', 'Arial', sans-serif;

$font-size-xxxlarge: 36px; // h1 !!!!
$font-size-xxlarge: 32px; // h2 !!!!
$font-size-xlarge: 26px; // h3
$font-size-large: 20px; // h4
$font-size: 18px; // h5
$font-size-medium: 18px; // default
$font-size-small: 14px; // h6, small, cite
$font-size-xsmall: 12px; // th

// Default border-radius value for input elements,
// alerts, buttons, images (in <figure>), ...

$line-height: 1.5em; // one size fits all

$border-radius: 0.3334em; // 5px @ 16px
$border-input: 1px solid $border-color-dark-grey;
$border-simple: 1px solid $border-color-grey;
$border-double: 2px solid $border-color-grey;

$box-shadow: 0 1px 3px 0 rgba($black, 0.1);
$box-shadow-card: 0 1px 12px rgba(104, 116, 131, 0.2);
$box-shadow-dashboard: 0 2px 5px 0 rgba($black, 0.05);
$box-shadow-big: 0 4px 10px 0 rgba($black, 0.2);
$text-shadow: 2px 2px 3px rgba($black, 0.1);

$loader-dot-size: 1rem;

$focus-outline: 1px solid $link-color;

// -------------------------------------------------------------------
// :: LAYOUT
// -------------------------------------------------------------------
$max-width: 1240px;
$wrapper-padding: 1.25rem;
$wrapper-padding-large: 1.8rem;
$wrapper-padding-small: 0.5rem;

// -------------------------------------------------------------------
// :: GRID
// -------------------------------------------------------------------
//$grid-gutter-row: 1.25rem;

// -------------------------------------------------------------------
// :: Z-INDEXES
// -------------------------------------------------------------------
$z-index-nav: 100;
$z-index-tooltips: 200;
$z-index-overlay: 300;
$z-index-leaflet-1: 400;
$z-index-leaflet-2: 500;
$z-index-header: 600;
$z-index-flyout: 700;
$z-index-modals: 800;

// -------------------------------------------------------------------
// :: ANIMATIONS
// -------------------------------------------------------------------
$animation-fast: 165ms ease-in-out;
$animation-medium: 330ms ease-in-out;
$animation-slow: 1000ms ease-in-out;

// -------------------------------------------------------------------
// :: HEADER
// -------------------------------------------------------------------
$header-height: 43px;
$header-menu-height: rem(43px);

// -------------------------------------------------------------------
// :: LAYOUT
// -------------------------------------------------------------------
// TODO: rem() function is no longer working because of an Angular 15 + CSS var issue.
$layout-spacing-xs: 0.625rem; // rem(10px)
$layout-spacing-s: 1.25rem; // rem(20px)
$layout-spacing: 1.875rem; // rem(30px)
$layout-spacing-md: 4.6875rem; // rem(75px)
$layout-spacing-l: 12.1875rem; // rem(195px)

// -------------------------------------------------------------------
// :: SIDEBAR
// -------------------------------------------------------------------
$sidebar-width-desktop-full: rem(375px);
$sidebar-width-desktop-collapsed: rem(60px);

// -------------------------------------------------------------------
// :: CONFIG
// -------------------------------------------------------------------
$container-max-width: 1725px;
$container-margin-xl: 195px;
$container-margin-l: 80px;
$container-margin: auto;

$breadcrumbs-icon: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='rgba(41, 41, 41, 1)'%3E%3Cpath fill-rule='evenodd' d='M4.694 3.644a.508.508 0 010-.71.497.497 0 01.7-.006l4.06 4.084-4.055 4.054a.505.505 0 01-.707 0 .501.501 0 01.002-.71l3.352-3.351-3.352-3.36z'/%3E%3C/svg%3E");
$link-underline: underline;

$tag-padding-size: 0 5px;
$tag-font-size: 14px;
$tag-transition: unset;
$tag-trigger-border-radius: 50%;
$tag-border-radius: 6px;
$tag-trigger-size: 20px;

$filter-font-size: 18px;
$filter-font-family: $font-family-medium;
$filter-margin-top: 12px;
$filter-checkbox-font-size: 14px;

$menu-item-font-family: $font-family-sans;
$menu-item-active-font-family: $font-family-medium;
$menu-item-font-size: 22px;

$checkbox-font-size: 18px;
$checkbox-size: 24px;
$checkbox-icon-size: 30px;
$checkbox-top: 60%;
$checkbox-left: 55%;
$checkbox-alignement: start;

$product-grid-gap: 30px;

$font-bold-font-family: $font-family-medium;
$font-bold-font-weight: normal;
