// -------------------------------------------------------------------
// :: SPACING
// -------------------------------------------------------------------
// Helper classes for adding or removing space between elements

// -------------------------------------------------------------------
// :: PADDING
// -------------------------------------------------------------------
.u-no-padding {
	padding: 0 !important;
}

.u-padding-sm {
	padding: rem(10px) !important;
}

.u-padding {
	padding: rem(20px) !important;
}

.u-padding-lg {
	padding: rem(30px) !important;
}

.u-padding-xl {
	padding: rem(40px) !important;
}

// -------------------------------------------------------------------
// :: PADDING TOP
// -------------------------------------------------------------------
.u-no-padding-top {
	padding-top: 0 !important;
}

.u-padding-top-sm {
	padding-top: rem(10px) !important;
}

.u-padding-top {
	padding-top: 2rem !important;
}

.u-padding-top-lg {
	padding-top: rem(30px) !important;
}

.u-padding-top-xl {
	padding-top: rem(40px) !important;
}

// -------------------------------------------------------------------
// :: PADDING RIGHT
// -------------------------------------------------------------------
.u-no-padding-right {
	padding-right: 0 !important;
}

.u-padding-right-sm {
	padding-right: rem(10px) !important;
}

.u-padding-right {
	padding-right: rem(20px) !important;
}

.u-padding-right-lg {
	padding-right: rem(30px) !important;
}

.u-padding-right-xl {
	padding-right: rem(40px) !important;
}

// -------------------------------------------------------------------
// :: PADDING BOTTOM
// -------------------------------------------------------------------
.u-no-padding-bottom {
	padding-bottom: 0 !important;
}

.u-padding-bottom-sm {
	padding-bottom: rem(10px) !important;
}

.u-padding-bottom {
	padding-bottom: rem(20px) !important;
}

.u-padding-bottom-lg {
	padding-bottom: rem(30px) !important;
}

.u-padding-bottom-xl {
	padding-bottom: rem(40px) !important;
}

.u-padding-bottom-xxl {
	padding-bottom: rem(60px) !important;
}

// -------------------------------------------------------------------
// :: PADDING LEFT
// -------------------------------------------------------------------
.u-no-padding-left {
	padding-left: 0 !important;
}

.u-padding-left-sm {
	padding-left: rem(10px) !important;
}

.u-padding-left {
	padding-left: rem(20px) !important;
}

.u-padding-left-lg {
	padding-left: rem(30px) !important;
}

.u-padding-left-xl {
	padding-left: rem(40px) !important;
}

// -------------------------------------------------------------------
// :: MARGIN
// -------------------------------------------------------------------
.u-no-margin {
	margin: 0 !important;
}

.u-margin-sm {
	margin: rem(10px) !important;
}

.u-margin {
	margin: rem(20px) !important;
}

.u-margin-lg {
	margin: rem(30px) !important;
}

.u-margin-xl {
	margin: rem(40px) !important;
}

// -------------------------------------------------------------------
// :: MARGIN TOP
// -------------------------------------------------------------------
.u-no-margin-top {
	margin-top: 0 !important;
}

.u-margin-top-xs {
	margin-top: rem(5px) !important;
}

.u-margin-top-sm {
	margin-top: rem(10px) !important;
}

.u-margin-top {
	margin-top: rem(20px) !important;
}

.u-margin-top-lg {
	margin-top: rem(30px) !important;
}

.u-margin-top-xl {
	margin-top: rem(40px) !important;
}

.u-margin-top-xxl {
	margin-top: rem(80px) !important;
}

// -------------------------------------------------------------------
// :: MARGIN RIGHT
// -------------------------------------------------------------------
.u-no-margin-right {
	margin-right: 0 !important;
}

.u-margin-right-sm {
	margin-right: rem(10px) !important;
}

.u-margin-right {
	margin-right: rem(20px) !important;
}

.u-margin-right-lg {
	margin-right: rem(30px) !important;
}

.u-margin-right-xl {
	margin-right: rem(40px) !important;
}

// -------------------------------------------------------------------
// :: MARGIN BOTTOM
// -------------------------------------------------------------------
.u-no-margin-bottom {
	margin-bottom: 0 !important;
}

.u-margin-bottom-sm {
	margin-bottom: rem(10px) !important;
}

.u-margin-bottom-1x-sm {
	margin-bottom: rem(15px) !important;
}

.u-margin-bottom {
	margin-bottom: rem(20px) !important;
}

.u-margin-bottom-1x-lg {
	margin-bottom: rem(25px) !important;
}

.u-margin-bottom-lg {
	margin-bottom: rem(30px) !important;
}

.u-margin-bottom-xl {
	margin-bottom: rem(40px) !important;
}

.u-margin-bottom-xxl {
	margin-bottom: rem(80px) !important;
}

// -------------------------------------------------------------------
// :: MARGIN LEFT
// -------------------------------------------------------------------
.u-no-margin-left {
	margin-left: 0 !important;
}

.u-margin-left-sm {
	margin-left: rem(10px) !important;
}

.u-margin-left {
	margin-left: rem(20px) !important;
}

.u-margin-left-lg {
	margin-left: rem(30px) !important;
}

.u-margin-left-xl {
	margin-left: rem(40px) !important;
}
