.c-hero {
	padding: rem(72px) rem(36px);

	position: relative;
	overflow: hidden;

	min-height: rem(600px);
}

.c-hero__visual {
	background: url('../../img/hero-bg.jpg') no-repeat left top transparent;
	background-size: auto 100%;

	position: absolute;
	width: 50%;
	height: 100%;
	top: 0;
	left: 50%;
	z-index: -2;

	@include respond-at(1800px) {
		background-position: right top;
		background-size: 100% auto;
	}

	@include respond-to($desktop) {
		background-size: cover;
		width: 100%;
		left: 0;
	}
}

.c-hero__cutoff {
	background-color: $primary-color;
	clip-path: polygon(0 0, 71.5% 0, 100% 100%, 0% 100%);
	position: absolute;
	width: 70%;
	height: 1000px;
	top: 0;
	left: 0;
	z-index: -1;

	@include respond-to($desktop) {
		display: none;
	}
	@include respond-at(1800px) {
		clip-path: polygon(0 0, 71.5% 0, 95% 100%, 0% 100%);
	}
}

.c-maintenance {
	.c-hero {
		min-height: unset;
	}
}
