// Abdurrhaman: Overwrite the default styles of the cookie consent component to match with the our styling
#cc-main {
	--blue-vlaanderen: hsla(210, 100%, 40%, 1);
	--blue-dark: #002776;

	/** Change button primary color **/
	--cc-btn-primary-bg: var(--blue-vlaanderen);
	--cc-btn-primary-border-color: var(--blue-vlaanderen);
	--cc-btn-primary-hover-bg: var(--blue-dark);
	--cc-btn-primary-hover-border-color: var(--blue-dark);

	/** Change button secondary color **/
	--cc-btn-secondary-bg: #fff;
	--cc-btn-secondary-border-color: var(--blue-vlaanderen);
	--cc-btn-secondary-hover-bg: var(--blue-dark);
	--cc-btn-secondary-hover-color: #fff;
	--cc-btn-secondary-color: var(--blue-vlaanderen);

	/** Also make toggles the same color as the button **/
	--cc-toggle-on-bg: var(--cc-btn-primary-bg);

	/** Make the buttons a bit rounder **/
	--cc-btn-border-radius: 0;
	--cc-modal-border-radius: 0;

	.cm__btn,
	.pm__btn {
		text-transform: uppercase !important;
	}

	.cm__title,
	.pm__title {
		font-family: $font-family-sans !important;
		font-size: $font-size-large !important;
	}

	.cm__desc,
	.pm__desc,
	.pm__section-desc {
		font-family: $font-family-sans !important;
		font-size: $font-size !important;
	}

	.pm__close-btn {
		border: none !important;
	}

	.cc__link,
	a {
		color: var(--blue-vlaanderen) !important;
		transition: none !important;
	}
}
