// -------------------------------------------------------------------
// :: OVERLAY
// -------------------------------------------------------------------

.u-overlay {
	position: relative;

	&::before {
		content: '';
		height: 100%;
		width: 100%;

		position: absolute;
		z-index: -1;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		background-color: transparent;

		transition: background-color $animation-medium;
	}

	&.is-active {
		&::before {
			background-color: rgba($grey, 0.2);
			z-index: $z-index-overlay;
		}
	}
}
