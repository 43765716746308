.c-page-overview {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;
	padding: 0;
	margin-top: $layout-spacing;
	margin-bottom: $layout-spacing;

	list-style-type: none;

	// Fix: Indicate "clickable" parts of the breadcrumb.
	// The following fix will change the colour of items that are not clickable
	// 	by picking out the anchors that do not have a href attribute (Denis).
	a.c-page-overview__link:not([href]) {
		color: $font-color;
		cursor: default;

		&:hover {
			text-decoration: none;
		}
	}

	&.c-page-overview--absolute {
		background-color: white;
		padding: rem(6px);
	}
}

.c-page-overview__item {
	display: flex;
	align-items: center;

	& + .c-page-overview__item {
		margin-left: rem(12px);

		&::before {
			content: '';

			display: block;
			width: rem(16px);
			height: rem(16px);
			margin-right: rem(12px);

			background-image: $breadcrumbs-icon;
			background-repeat: no-repeat;
			background-size: rem(16px);
		}
	}
}

.c-page-overview__link {
	font-family: $font-family-primary;
	font-size: rem(14px);
	line-height: rem(16px);
	text-decoration: none;

	color: $link-color;

	transition: color 0.2s ease-in-out;

	&:hover {
		text-decoration: underline;

		color: $link-color-dark;
	}
}
