@use 'sass:math';

// -------------------------------------------------------------------
// :: CSS SHAPES
// -------------------------------------------------------------------
// Shorthand methods for creating CSS shapes. Shapes may looked blurry
// on hdpi screens when hardware acceleration is being applied.
//
// Inspired by:
// - https://css-tricks.com/examples/ShapesOfCSS

// -------------------------------------------------------------------
// :: TRIANGLE
// -------------------------------------------------------------------
// .css-triangle {
//		@include FLOW-css-triangle("up", 20px, #F00);
//		@include FLOW-css-triangle("right", 2em, #F00, true);
// }

@mixin FLOW-css-triangle($_direction, $_size, $_color: null, $_eq: false) {
	content: '';

	// Set triangle base for regular
	// and equilateral triangles

	$_eq-size: $_size;
	@if $_eq {
		$_eq-size: $_size * 2 * 0.866;
	}

	// Set triangle directions
	// and base styles

	@if $_direction == 'up' {
		border-left: $_size solid transparent !important;
		border-right: $_size solid transparent !important;
		border-bottom: $_eq-size solid $_color;
	}

	@if $_direction == 'down' {
		border-left: $_size solid transparent !important;
		border-right: $_size solid transparent !important;
		border-top: $_eq-size solid $_color;
	}

	@if $_direction == 'left' {
		border-top: $_size solid transparent !important;
		border-bottom: $_size solid transparent !important;
		border-right: $_eq-size solid $_color;
	}

	@if $_direction == 'right' {
		border-top: $_size solid transparent !important;
		border-bottom: $_size solid transparent !important;
		border-left: $_eq-size solid $_color;
	}
}

// -------------------------------------------------------------------
// :: TRIANGLE WITH BORDER
// -------------------------------------------------------------------
// .css-triangle {
//		@include FLOW-css-triangle("up", 20px, $white, $border-color);
// }

@mixin FLOW-css-triangle-with-border($_direction, $_size, $_fill: $white, $_border-color: null) {
	content: '';
	position: absolute;
	background-color: $_fill;
	border-top: 1px solid $_border-color;
	border-left: 1px solid $_border-color;
	width: $_size;
	height: $_size;

	@if $_direction == 'up' {
		transform: rotate(45deg);
		top: -$_size * 0.5;
	}

	@if $_direction == 'down' {
		transform: rotate(-135deg);
		bottom: -$_size * 0.5;
	}

	@if $_direction == 'left' {
		transform: rotate(-45deg);
		left: -$_size * 0.5;
	}

	@if $_direction == 'right' {
		transform: rotate(135deg);
		right: -$_size * 0.5;
	}
}

// -------------------------------------------------------------------
// :: Clip path for slanting images
// -------------------------------------------------------------------
// .some-element {
//		@include SLANT-left(300px);
// }
@mixin SLANT-left($_height, $_color: $background-color-base) {
	position: relative;
	overflow: hidden;

	&::before {
		content: '';
		display: block;
		position: absolute;
		background-color: $_color;
		left: 0;
		height: $_height;
		width: calc($_height / math.tan(78deg));
		z-index: 1;
		clip-path: polygon(-1% 0, -1% 101%, 100% 101%);
	}
}

// -------------------------------------------------------------------
// :: Clip path for slanting images
// -------------------------------------------------------------------
// .some-element {
//		@include SLANT-right(300px);
// }
@mixin SLANT-right($_height, $_color: $background-color-base, $_z-index: 1, $_offset: 0) {
	position: relative;
	overflow: hidden;

	&::after {
		content: '';
		display: block;
		position: absolute;
		background-color: $_color;
		top: 0;
		right: $_offset;
		bottom: 0;
		height: $_height;
		width: calc($_height * math.tan(12deg));
		z-index: 1;
		clip-path: polygon(100% 0, 0 100%, 100% 100%);
	}
}

// .some-element {
//		@include SLANT-bottom-vertical(300px);
// }
//Robbe: Below slant is calculated based on the fixed height of your element
@mixin SLANT-bottom-vertical($_height, $_color: $background-color-base, $_z-index: 1, $_offset: 0) {
	position: relative;
	overflow: hidden;

	@if ($_offset > 0) {
		&::before {
			content: '';
			display: block;
			position: absolute;
			background-color: $_color;
			bottom: $_offset;
			left: 0;
			height: $_height;
			width: calc($_height / math.tan(12deg));
			z-index: $_z-index;
			clip-path: polygon(100% 0, 0 100%, 100% 100%);
		}

		&::after {
			content: '';
			display: block;
			position: absolute;
			background-color: $_color;
			right: 0;
			bottom: 0;
			left: 0;
			height: $_offset;
			z-index: $_z-index;
		}
	} @else {
		&::after {
			content: '';
			display: block;
			position: absolute;
			background-color: $_color;
			bottom: -1px;
			left: 0;
			height: $_height;
			width: calc($_height / math.tan(12deg));
			z-index: $_z-index;
			clip-path: polygon(100% 0, 0 100%, 100% 100%);
		}
	}
}

// .some-element {
//		@include SLANT-bottom-horizontal(300px);
// }
//Robbe: Below slant is calculated based on the fixed width of your element
@mixin SLANT-bottom-horizontal($_width, $_color: $background-color-base) {
	position: relative;
	overflow: hidden;

	&::after {
		content: '';
		display: block;
		position: absolute;
		background-color: $_color;
		bottom: -1px;
		left: 0;
		width: $_width;
		height: calc($_width * math.tan(12deg));
		z-index: 1;
		clip-path: polygon(100% 0, 0 101%, 100% 101%);
	}
}

// .some-element {
//		@include SLANT-bottom-horizontal-left(300px, $primary-color);
// }
//Robbe: Below slant is calculated based on the fixed width of your element
@mixin SLANT-bottom-horizontal-left($_height, $_color, $_z-index: 1) {
	position: relative;
	overflow: hidden;

	&::after {
		content: '';
		display: block;
		position: absolute;
		background-color: $_color;
		bottom: -1px;
		left: 0;
		width: 100%;
		height: $_height;
		z-index: $_z-index;
		clip-path: polygon(0% 0%, 0% 100%, 100% 100%);
	}
}

// .some-element {
//		@include SLANT-bottom-horizontal-right(300px, $primary-color);
// }
//Robbe: Below slant is calculated based on the fixed width of your element
@mixin SLANT-bottom-horizontal-right($_height, $_color, $_z-index: 1) {
	position: relative;
	overflow: hidden;

	&::after {
		content: '';
		display: block;
		position: absolute;
		background-color: $_color;
		bottom: -1px;
		left: 0;
		width: 100%;
		height: $_height;
		z-index: $_z-index;
		clip-path: polygon(100% 0, 0 100%, 100% 100%);
	}
}

// .some-element {
//		@include SLANT-top-horizontal(300px);
// }
@mixin SLANT-top-horizontal($_height, $_color: $background-color-base) {
	position: relative;
	overflow: hidden;

	&::before {
		content: '';
		display: block;
		position: absolute;
		background-color: $_color;
		top: -1px;
		left: 0;
		width: 100%;
		height: calc($_height * math.tan(12deg));
		z-index: 1;
		clip-path: polygon(100% 0, 0 0, 100% 100%);
	}
}

// .some-element {
//		@include SLANT-top-horizontal-left(300px);
// }
@mixin SLANT-top-horizontal-left($_height, $_color: $background-color-base, $_z-index: 1) {
	position: relative;
	overflow: hidden;

	&::before {
		content: '';
		display: block;
		position: absolute;
		background-color: $_color;
		top: 0;
		left: 0;
		width: 100%;
		height: calc($_height);
		z-index: $_z-index;
		clip-path: polygon(100% 0, 0 0, 0 100%);
	}
}

// .some-element {
//		@include SLANT-top-horizontal-right(300px);
// }
@mixin SLANT-top-horizontal-right($_height, $_color: $background-color-base, $_z-index: 1) {
	position: relative;
	overflow: hidden;

	&::before {
		content: '';
		display: block;
		position: absolute;
		background-color: $_color;
		top: -1px;
		left: 0;
		width: 100%;
		height: calc($_height);
		z-index: $_z-index;
		clip-path: polygon(100% 0, 0 0, 100% 100%);
	}
}

// .some-element {
//		@include BG-half-horizontal('top', $white, -2);
// }
@mixin BG-half-horizontal($_type, $_color: $background-color-light-grey, $_z-index: -1) {
	position: relative;
	overflow: hidden;

	@if ($_type == 'top') {
		&::before {
			content: '';
			position: absolute;
			z-index: $_z-index;
			top: 0;
			left: 0;
			width: 100%;
			height: 50%;
			background-color: $_color;
		}
	}

	@if ($_type == 'bottom') {
		&::after {
			content: '';
			position: absolute;
			z-index: $_z-index;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 50%;
			background-color: $_color;
		}
	}
}
