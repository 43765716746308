$m2-vlo-yellow: (
	50: #fffce3,
	100: #fff8b9,
	200: #fff38a,
	300: #ffee5b,
	400: #ffea38,
	500: #ffe615,
	600: #ffe312,
	700: #ffdf0f,
	800: #ffdb0c,
	900: #ffd506,
	A100: #ffffff,
	A200: #ffffff,
	A400: #ffffff,
	A700: #fff7e5,
	contrast: (
		50: #000000,
		100: #000000,
		200: #000000,
		300: #000000,
		400: #000000,
		500: #000000,
		600: #000000,
		700: #000000,
		800: #000000,
		900: #000000,
		A100: #000000,
		A200: #000000,
		A400: #000000,
		A700: #000000
	)
);

$m2-vlo-blue: (
	50: #e0edf9,
	100: #b3d1f0,
	200: #80b3e6,
	300: #4d94db,
	400: #267dd4,
	500: #0066cc,
	600: #005ec7,
	700: #0053c0,
	800: #0049b9,
	900: #0038ad,
	A100: #d7eaff,
	A200: #a4ceff,
	A400: #71b3ff,
	A700: #58a6ff,
	contrast: (
		50: #000000,
		100: #000000,
		200: #000000,
		300: #000000,
		400: #ffffff,
		500: #ffffff,
		600: #ffffff,
		700: #ffffff,
		800: #ffffff,
		900: #ffffff,
		A100: #000000,
		A200: #000000,
		A400: #000000,
		A700: #000000
	)
);

$m2-vlo-red: (
	50: #f8e6e6,
	100: #eec1c1,
	200: #e29898,
	300: #d66e6e,
	400: #ce4f4f,
	500: #c53030,
	600: #bf2b2b,
	700: #b82424,
	800: #b01e1e,
	900: #a31313,
	A100: #ffeff2,
	A200: #ffbcc7,
	A400: #ff899d,
	A700: #ff6f88,
	contrast: (
		50: #000000,
		100: #000000,
		200: #000000,
		300: #000000,
		400: #ffffff,
		500: #ffffff,
		600: #ffffff,
		700: #ffffff,
		800: #ffffff,
		900: #ffffff,
		A100: #000000,
		A200: #000000,
		A400: #000000,
		A700: #000000
	)
);

$m2-vlo-grey: (
	50: #ecefef,
	100: #cfd6d6,
	200: #afbbbb,
	300: #8f9fa0,
	400: #778b8b,
	500: #5f7677,
	600: #576e6f,
	700: #4d6364,
	800: #43595a,
	900: #324647,
	A100: #bbfef4,
	A200: #89fdeb,
	A400: #54ffe5,
	A700: #3affe1,
	contrast: (
		50: #000000,
		100: #000000,
		200: #000000,
		300: #000000,
		400: #000000,
		500: #ffffff,
		600: #ffffff,
		700: #ffffff,
		800: #ffffff,
		900: #ffffff,
		A100: #000000,
		A200: #000000,
		A400: #000000,
		A700: #000000
	)
);

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$v-loket-primary: mat.m2-define-palette($m2-vlo-grey);
$v-loket-accent: mat.m2-define-palette($m2-vlo-yellow);

// The warn palette is optional (defaults to red).
$v-loket-warn: mat.m2-define-palette($m2-vlo-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$v-loket-theme: mat.m2-define-light-theme(
	(
		color: (
			primary: $v-loket-primary,
			accent: $v-loket-accent,
			warn: $v-loket-warn
		)
	)
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($v-loket-theme);

// --------------------
// Custom Styles
// --------------------
.cdk-overlay-backdrop {
	&.c-menu__backdrop.cdk-overlay-backdrop-showing {
		background-color: rgba(0, 0, 0, 0.8);
	}
}
