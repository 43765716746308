.c-sidebar {
	.c-sidebar__sidebar-content {
		.c-sidebar__sidebar-content__header {
			@extend .c-vloket-text--lg;

			@include respond-to($desktop) {
				border-left: 4px solid $border-color-primary;
			}

			&__title {
				@include respond-to($desktop) {
					background-color: $background-color-blue-grey-light;
					padding: $layout-spacing-s $layout-spacing;
				}

				@include respond-at($desktop) {
					max-width: $sidebar-width-desktop-full;
				}
			}
		}

		.c-sidebar__close-button {
			@extend .c-vloket-text--lg;

			background-color: transparent;
			border: none;
			min-height: 100%;
			min-width: 100%;
			border-right: 4px solid transparent;

			&:hover {
				color: $text-color-blue;
				border-right-color: $border-color-blue-grey;
			}
		}

		.c-sidebar-item {
			@extend .c-vloket-text--lg;

			display: block;
			padding: $layout-spacing-s $layout-spacing;
			border-left: 4px solid transparent;
			color: $black;
			text-decoration: none;
			transition: 0.2s ease-in-out all;

			.c-sidebar__show-all-button {
				@extend .c-vloket-text--lg;

				background-color: transparent;
				border: none;

				&:hover {
					color: $text-color-blue;
				}

				fa-icon {
					margin-right: 0;
				}
			}

			fa-icon {
				margin-right: $layout-spacing;
			}

			&--active {
				background-color: $white;
				border-left-color: $border-color-primary;
				font-family: $font-family-bold;
				pointer-events: none;
				outline: none;
			}

			&--icon-only {
				display: none;
				visibility: hidden;
			}

			&:not(.c-sidebar-item--active):hover {
				background-color: $white;
				border-left-color: $border-color-blue-grey;
			}
		}
	}

	@include respond-to($desktop) {
		height: fit-content;

		.c-sidebar__sidebar-content {
			display: grid;
			grid-template-areas:
				'title button'
				'items button';
			grid-template-columns: auto $layout-spacing-md;
			grid-template-rows: max-content;
			align-items: start;
			background-color: $background-color-blue-grey-light;
			border-bottom: 1px solid $border-color-blue-grey;

			&__items {
				grid-area: items;
			}

			.c-sidebar-item {
				background-color: $background-color-blue-grey-light;
				display: flex;
				justify-content: flex-start;
			}

			.c-sidebar__close-button {
				grid-area: button;
				width: 100%;

				&:hover {
					color: $text-color-blue;
					border-right-color: transparent;
				}
			}

			&--open-on-mobile {
				grid-template-areas:
					'title button'
					'items items';
				.c-sidebar__close-button {
					transform: rotate(180deg);
				}
			}
		}
	}

	@include respond-at($desktop) {
		.c-sidebar__sidebar-content {
			background-color: $background-color-blue-grey-light;
			border-right: 1px solid $border-color-blue-grey;

			.c-sidebar__sidebar-content__header {
				display: grid;
				grid-template-areas: 'title button';
				grid-template-columns: auto $layout-spacing-md;
				align-items: start;

				&__title {
					padding: $layout-spacing 0 $layout-spacing $layout-spacing;
				}
			}
		}

		&.c-sidebar--collapsed {
			.c-sidebar__sidebar-content__header {
				display: flex;
				justify-content: center;

				.c-sidebar__close-button {
					padding: $layout-spacing-s 0 $layout-spacing-s 4px;
					transform: rotate(180deg);
				}
			}

			.c-sidebar__sidebar-content {
				.c-sidebar-item {
					padding: $layout-spacing-s 0;
					text-align: center;

					fa-icon {
						margin-right: 0;
					}
				}
			}
		}
	}
}
