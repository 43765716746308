// =============================================================================
// :: Font families
// =============================================================================
$font-family-sans: 'Flanders Art Sans - CJM', sans-serif;
$font-family-serif: 'Flanders Art Serif Pro - CJM', serif;
$font-family-mono: 'Droid Sans Mono - CJM', monospace;
$font-family-medium: 'Flanders Art Sans Medium - CJM', 'Arial', sans-serif;
$font-family-bold: 'Flanders Art Sans Bold - CJM', 'Arial', sans-serif;

$font-family-primary: ('Flanders Art Sans - CJM', 'Arial', sans-serif);
$font-family-primary-medium: ('Flanders Art Sans Medium - CJM', 'Arial', sans-serif);
$font-family-primary-bold: ('Flanders Art Sans Bold - CJM', 'Arial', sans-serif);

$font-family-secondary: ('Flanders Art Serif Pro - CJM', 'Times New Roman', serif);
$font-family-secondary-bold: ('Flanders Art Serif Pro Bold - CJM', 'Times New Roman', serif);

// =============================================================================
// :: Font weights
// =============================================================================
$font-weight-extrablack: 900;
$font-weight-black: 800;
$font-weight-ultrabold: 700;
$font-weight-bold: 600;
$font-weight-dark: 500;
$font-weight-normal: 400;
$font-weight-lite: 300;
$font-weight-thin: 200;
$font-weight-ultrathin: 100;
