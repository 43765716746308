// -------------------------------------------------------------------
// :: COLORS
// -------------------------------------------------------------------
// See styleguide.html for more info on how colors are being used

// Green variants
$green-30: hsla(148, 100%, 30%, 1);
$green-82: hsla(128, 25%, 82%, 1);
$green-light: hsla(148, 36%, 91%, 1);
$green-dark: rgb(6, 94, 47);
$green-fade: hsla(183, 65%, 46%, 0.05);
$green-indicator-background: hsla(148, 100%, 30%, 0.2);
$selection-color-green: rgba(6, 94, 47, 0.4);
$sea-green: #1c7074;

// Yellow variants (WIP)
$yellow-30: #ffe615;
$yellow-light: #fefadc;
$yellow-dark: #333332;

// Black
$black: hsla(0, 0%, 0%, 1);
$black-dark-blue: hsla(225, 12%, 26%, 1);
$black-light-blue: hsla(225, 16%, 60%, 1);
$black-title: #2f2e41;
$black-lighter: rgb(63, 61, 86);
$black-vlaanderen: #333332;

// Grey
$grey: #5f7677;
$grey-darker: #7b7b7b;
$grey-dark: #aeaeae;
$grey-darkest: #333333;
$grey-medium: #e8e8e8;
$grey-light: #f2f2f2;
$grey-lightest: #f5f5f5;
$grey-lighter: #f8f8f8;
$grey: #3b3f4b;
$grey-16: hsla(0, 0%, 16%, 1);
$grey-31: hsla(0, 0%, 31%, 1);
$grey-40: hsla(0, 0%, 40%, 1);
$grey-44: hsla(0, 0%, 44%, 1);
$grey-46: hsla(0, 0%, 46%, 1);
$grey-48: hsla(0, 0%, 48%, 1);
$grey-50: hsla(0, 0%, 50%, 1);
$grey-60: hsla(0, 0%, 60%, 1);
$grey-83-lighter: hsla(212, 15%, 83%, 1);
$grey-83: hsla(212, 17%, 83%, 1);
$grey-85: hsla(0, 0%, 85%, 1);
$grey-96: hsla(0, 0%, 96%, 1);
$grey-97: hsla(220, 18%, 97%, 1);
$grey-99: hsl(180, 20%, 99%);
$selection-color-grey: rgba(51, 51, 50, 0.4);

// White
$white: hsla(0, 0%, 100%, 1);
$white-98: hsla(0, 0%, 98%, 1);
$white-95: hsla(0, 0%, 95%, 1);
$white-93: hsla(0, 0%, 93%, 1);
$white-92: hsla(0, 0%, 92%, 1);
$white-70: hsla(0, 0%, 70%, 1);

// Orange
$orange: #e37222;
$orange-dark: darken($orange, 20%);

// Blue
$blue: #007ac9;
$blue-dark: #002776;
$blue-teal-light: #3fcfd5;
$blue-teal-lighter: #9cdcd9;
$blue-45: hsla(205, 77%, 45%, 1);
$blue-48: hsla(216, 98%, 48%, 1);
$blue-56: hsla(214, 48%, 56%, 1);
$blue-80: hsla(210, 4%, 80%, 1);
$blue-92: hsla(210, 5%, 92%, 1);
$blue-vlaanderen: hsla(210, 100%, 40%, 1);
$blue-vlaanderen-dark: hsla(220, 100%, 35%, 1);

// Modal
$modal-overlay-color: rgba(28, 30, 31, 0.9); // => #1C1E1F

// UI-colors
$ui-red: #df6d6d;
$ui-orange: #e69b1f;
$ui-yellow: #f0d505;
$ui-green: #8bae00;
$ui-blue: #32b2e9;
$ui-error: #c53030;
$ui-error-light: #fff0f0;
$ui-purple: #9500cc;

// Contextual
$primary-color: $yellow-30;
$primary-color-hover: $yellow-dark;
$primary-color-dark: $yellow-dark;
$primary-color-light: $yellow-light;
$secondary-color: $blue-56;
$indicator-background-color: $green-indicator-background;
$primary-color-selection: $selection-color-grey;

// Background
$background-color: $grey-lighter;
$background-color-base: $white;
$background-color-primary-dark: $primary-color-dark;
$background-color-primary-light: $primary-color-light;
$background-color-light: $grey-96;
$background-color-black: $black;
$background-color-regular: $white-95;
$background-color-medium: $grey-46;
$background-color-light-blue: $black-light-blue;
$background-color-light-grey: #f5f6f8;
$background-color-blue-grey-light: #fafbfb;
$background-color-blue-grey: #fafbfb;
$background-color-blue-grey-dark: #cdd3da;
$background-color-white-lilac: #f7f9fc;

// Border

$border-color: $grey-light;
$border-color-base: $blue-92;
$border-color-grey: $grey-83;
$border-color-light-grey: #f5f6f8;
$border-color-dark-grey: $grey-60;
$border-color-dark: $grey-85;
$border-color-dark-blue: $black-dark-blue;
$border-color-primary: $primary-color;
$border-color-blue-grey: #cdd3da;

// Fonts

$font-color: $grey-16; // Base
$font-color-medium: rgba($black, 0.82); // Secondary (eg. image caption)
$font-color-light: $grey; // Placeholders in forms
$font-color-light-secondary: $grey-dark;
$font-color-lighter: $grey-light; // Placeholders in forms
$font-color-disabled: $grey-light; // Disabled Text
$font-color-blue: $blue; // Link color
$font-color-blue-dark: $blue-dark; // Link color
$font-color-grey: $grey;
$font-color-grey-40: $grey-40;
$text-color-base: $grey-46;
$text-color-black: $grey-16;
$text-color-black-title: $grey-16;
$text-color-light: $white;
$text-color-medium: $blue-80;
$text-color-blue: $blue-45;
$text-color-blue-dark: $blue-48;
$text-color-blue-grey: #687483;
$text-color-blue-grey-light: #cdd3da;

// Selection
$selection-color: $primary-color-selection;

// Links
$link-color: $blue-vlaanderen;
$link-color-dark: $blue-vlaanderen-dark;

// Buttons
$button-primary: $primary-color;
$button-primary-hover: $black-vlaanderen;
$button-text: $black-vlaanderen;
$button-text-outline: $black-vlaanderen;
$button-text-hover: $text-color-light;
$button-link-color: $link-color;

// Checkbox
$checkbox-border: $border-color-dark-grey;
$checkbox-background: $white;
$checkbox-border-checked: $border-color-dark-grey;
$checkbox-background-checked: $white;
$checkbox-icon: $blue-vlaanderen;
$checkbox-icon-hover: $grey-83-lighter;

// Filters
$filter-title-border: $grey-83-lighter;
$filter-container-background-lg: $grey-97;
$filter-container-background-sm: $background-color-base;
$filter-label-count: $grey-44;

// Tag
$tag-background: $primary-color-light;
$tag-border: $primary-color;
$tag-icon-background: $primary-color;
$tag-icon-hover: $black;

// Pager
$pager-background: transparent;
$pager-background-hover: transparent;
$pager-color: $blue-vlaanderen;
$pager-color-hover: $blue-vlaanderen-dark;
$pager-number: $blue-vlaanderen;
$pager-number-active: $black-vlaanderen;
$pager-number-not-active-hover: $blue-vlaanderen-dark;

// Menu
$menu-item-active-color: $black;
$menu-item-border-active-color: $primary-color;

// Table
$table-even-color: $grey-97;

// Alert
$red-error: #db3434;
$orange-warning: #ffc515;
$green-success: #3ca854;
$blue-info: #0055cc;

$red-error-bg: #f5e8e7;
$orange-warning-bg: #fbf6e6;
$green-success-bg: #eaf3eb;
$blue-info-bg: #e4ebf6;

// Leaflet
$active-marker: #b2324d;

// Primary-button
$heart-red: #ca433c;
