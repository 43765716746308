.c-header__container {
	height: $header-height;
	width: 100%;
	display: flex;
	justify-content: space-between;

	a {
		text-decoration: none;
		color: $black-lighter;
	}

	.c-header__left {
		display: flex;
		vertical-align: middle;
		align-items: center;
		max-width: max-content;

		height: inherit;
	}

	.c-header__title {
		font-family: $font-family-primary-medium;
		font-size: 19px;

		&:hover {
			color: $primary-color;
		}
	}

	.c-header__subtitle {
		font-family: $font-family-primary-medium;
		text-transform: uppercase;
		font-size: 14px;

		margin-left: 30px;

		display: inline-block;
		vertical-align: bottom;
		line-height: 3.14286em;
		position: relative;

		@include respond-to($mobile-small) {
			line-height: 1em;
		}

		&:before {
			display: block;
			content: ' ';
			position: absolute;
			left: 0;
			top: 10px;
			bottom: 10px;
			width: 1px;
			margin-left: -20px;

			background-color: $white-93 !important;
			transform: rotate(-20deg);
			transform-origin: top left;
			box-sizing: inherit;
		}

		&:hover {
			color: $primary-color;
		}
	}

	.c-header--logo {
		display: inline-block;
		height: inherit;
		margin-right: 15px;

		img {
			margin: 9px;
			height: calc(100% - 18px);
		}

		&:after {
			content: '';
			display: block;
			position: absolute;
			left: 28px;
			top: -4px;
			height: 53px;
			width: 1px;
			background-image: none !important;
			background-color: $white-93;
			transform: rotate(-20deg);
			transform-origin: top left;
		}
	}
}

.c-header__block {
	& + .c-header__block {
		border-top: rem(1px) solid $white-93;
	}
}
