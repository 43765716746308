// -------------------------------------------------------------------
// :: ALERTS
// -------------------------------------------------------------------
.c-alert {
	display: flex;
	align-items: flex-start;
	color: $font-color;
	border: $border-simple;
	border-radius: $border-radius;
	padding: $layout-spacing-xs;
	margin: 1rem 0;
	box-shadow: $box-shadow-card;

	p {
		margin: 0;
	}

	&__icon {
		font-size: $font-size;
		padding-right: $layout-spacing-xs;
	}

	&__content {
		width: 100%;
		vertical-align: top;

		&__header {
			font-family: $font-family-medium;
		}
	}

	.c-button {
		color: $font-color;
		margin-left: $layout-spacing-xs;
	}
}

.c-alert [class*='icon']:before {
	font-size: 1.65em;
	line-height: 0;
	position: relative;
	margin-right: 0.3em;
	top: -0.065em;
}

// -------------------------------------------------------------------
// :: ALERT CLASSES
// -------------------------------------------------------------------

.c-alert.is-error {
	color: $red-error;
	border-color: $red-error;
	background-color: $red-error-bg;
}

.c-alert.is-warning {
	color: $orange-warning;
	border-color: $orange-warning;
	background-color: $orange-warning-bg;
}

.c-alert.is-success {
	color: $green-success;
	border-color: $green-success;
	background-color: $green-success-bg;
}

.c-alert.is-information {
	color: $blue-info;
	border-color: $blue-info;
	background-color: $blue-info-bg;
}
