@import '@cjm/styles/base/_quarks/colors';
@import '@cjm/styles/base/utils/_functions';
@import '@cjm/styles/base/utils/_media-queries';
@import '@cjm/styles/base/_globals';
@import '@cjm/styles/base/utils/_css-shapes.scss';

.u-wysiwyg {
	// Denis: prevent large words or URL's from pushing the width of the product card.
	word-break: break-word; // This is deprecated as stated here: https://developer.mozilla.org/en-US/docs/Web/CSS/word-break
	overflow-wrap: anywhere; // This is the replacement, but is not yet fully supported as stated here: https://developer.mozilla.org/en-US/docs/Web/CSS/overflow-wrap

	p {
		font-size: rem($font-size);
		margin-bottom: $layout-spacing-xs;

		&:first-child {
			margin-bottom: $layout-spacing-xs;
		}
	}

	h2 {
		@extend .c-vloket-text-marking;
		@extend .c-vloket-text-marking--line-through;
		font-size: rem($font-size-xxlarge);
		display: inline-block;
	}

	ul {
		li::marker {
			content: '> ';
		}
	}

	ul,
	ol,
	table {
		font-size: rem($font-size);
		margin-bottom: $layout-spacing-s;
	}

	blockquote {
		border-left: $border-double;
		margin-left: $layout-spacing-xs;
		margin-bottom: $layout-spacing-xs;
		padding: $layout-spacing-xs $layout-spacing;

		p {
			line-height: $line-height;
			font-size: rem($font-size);
			font-style: italic;
			margin-bottom: 0;
		}
	}
}
