@import '../base/_quarks/colors';
@import '../base/utils/functions';

$table-header-font-family: ('Flanders Art Sans Medium - CJM', 'Arial', sans-serif);
$table-header-text-transform: none;
$table-header-size: rem(18px);
$table-font-size: rem(18px);
$table-row-padding: rem(19px) rem(18px);
$table-line-height: rem(27px);

$border-left: 0;
$border-right: 0;

.cjm-table {
	// Denis: make sure the table doesn't cause the entire container to scroll vertically.
	overflow-x: auto;
	// Iben: This fixes a common issue with Firefox that hasn't been fixed for 5 years.
	// https://bugzilla.mozilla.org/show_bug.cgi?id=1416504
	padding-left: 1px;

	table {
		border-spacing: 0;
		margin: 0;

		border-collapse: collapse;
		border: none !important;
		table-layout: initial !important;

		th {
			position: relative;

			font-family: $table-header-font-family !important;
			text-transform: $table-header-text-transform !important;
			font-size: $table-header-size !important;
		}
	}

	tbody {
		border-bottom: 1px solid $border-color-grey;
		.ngx-table-row {
			border: 1px solid $border-color-grey;
			border-left: $border-left;
			border-right: $border-right;
			border-bottom: none;

			&:focus-visible {
				outline-color: $primary-color;
			}

			&.ngx-table-row-even {
				background: $table-even-color;
			}

			&.ngx-table-row-odd {
				background: $white;
			}

			&.ngx-table-row-selected {
				border-color: $primary-color;
				background: $white !important;

				&.ngx-table-row-highlight {
					background: $white !important;
				}
			}

			&.ngx-table-row-highlight {
				background-color: $primary-color-light !important;
				font-weight: 600;
			}
		}

		.ngx-table-detail-row-open {
			border-color: $primary-color;
			border-top: none;
		}
	}

	.cdk-header-row {
		.cdk-header-cell {
			border: none !important;
			white-space: nowrap;
		}
	}

	.ngx-table-cell {
		font-size: $table-font-size;
		padding: $table-row-padding !important;
		line-height: $table-line-height;
		vertical-align: middle;

		border: none !important;
	}

	.c-filter__input {
		background-color: $white;
		border-color: $border-color-grey;
	}

	.cjm-action-cell {
		width: 45px;
		max-width: 45px;
		padding: 5px !important;
		text-align: center;

		button {
			padding: 10px;
			height: 36px;
			width: 36px;

			background-color: transparent;
			border: none;

			&.cjm-action-cell-header {
				background-color: $grey-light;
				border-radius: 50px;

				&:hover {
					background-color: darken($grey-light, 5);
				}
			}

			&.is-warning {
				color: $ui-orange;

				&:hover {
					color: darken($ui-orange, 5);
				}
			}

			&.is-danger {
				color: $ui-red;

				&:hover {
					color: darken($ui-red, 5);
				}
			}

			&.is-alert {
				color: $ui-yellow;

				&:hover {
					color: darken($ui-yellow, 5);
				}
			}

			&.is-positive {
				color: $green-30;

				&:hover {
					color: darken($green-30, 5);
				}
			}
		}
	}
}
