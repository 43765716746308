// -------------------------------------------------------------------
// :: Loaders
// -------------------------------------------------------------------

// FULLSCREEN PRELOADER

.c-preloader {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	display: table;

	> span {
		display: block;

		@include respond-at($tablet) {
			display: table-cell;
			vertical-align: middle;
		}
	}
}

.c-preloader__logo {
	color: $primary-color-dark;
	font-size: 160px;
	line-height: 0;
	animation: breathe 3s infinite;
}

.c-preloader__logo-image {
	animation: breathe 3s infinite;
}

.c-preloader__info {
	font-size: $font-size-large;
	color: $font-color-medium;
	padding: 0 1rem;
}

//inline loader

.c-loader-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding: rem(30px);

	> p {
		margin-top: rem(8px);

		text-align: center;
	}

	&.c-loader--inline-row {
		display: inline-flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		width: auto;
		padding: 0;

		.c-loader {
			margin: 0 $loader-dot-size * 1.4;
		}

		> p {
			margin-top: 0;
			margin-left: rem(8px);

			text-align: left;
		}
	}
}

.c-loader {
	display: block;
	position: relative;
	margin: 0.5rem auto;
	width: $loader-dot-size;
	height: $loader-dot-size;

	&.c-loader--inline {
		display: inline-block;
		margin: 0 $loader-dot-size * 1.4;
	}
}

.c-loader span,
.c-loader:before,
.c-loader:after {
	content: '';
	width: $loader-dot-size;
	height: $loader-dot-size;
	position: absolute;
	top: 0;
	border-radius: 50%;
	border-style: solid;
	border-color: $primary-color;
	animation: growing-border 1.2s infinite;
}

.c-loader span {
	left: 0;
	animation-delay: 125ms;
}

.c-loader:before {
	left: -$loader-dot-size * 1.4;
	animation-delay: 0ms;
}

.c-loader:after {
	left: auto;
	right: -$loader-dot-size * 1.4;
	animation-delay: 250ms;
}
