.c-vloket-snack-bar {
	// Material overwrites
	&.mat-mdc-snack-bar-container {
		/*
		 * Denis:
		 * This element needs to have an offset for both headers
		 * and an additional spacing to create margin between the headers
		 * and the snackbar.
		 */
		margin-top: calc(($header-menu-height * 2) + $layout-spacing-xs);
		margin-bottom: $layout-spacing-s;

		.mdc-snackbar__surface {
			background-color: transparent;
			padding: 0;
		}

		.mat-mdc-snack-bar-label {
			padding: 0;
		}
	}

	// Toaster overwrites
	.c-toaster {
		margin: 0 !important;
	}
}
