// -------------------------------------------------------------------
// :: FORMS
// -------------------------------------------------------------------
// Every form should have an action- and method-attribute
// The submit button should be added as input[type=submit]
//
// Every input should have a label with corresponding for-, id- and
// name-attributes. Labels are always placed before inputs, nesting
// is not allowed (except for checkboxes and radio's)

@include FLOW-normalize-forms();

// -------------------------------------------------------------------
// :: BASE
// -------------------------------------------------------------------

select,
textarea,
input.c-input--as-textfield {
	font-size: rem($font-size);
	border-radius: 0;
	padding: 0.6rem 0.8rem;
	border: none;
	width: 100%;
	min-height: rem(40px);

	@include FLOW-input-placeholder() {
		color: rgba($font-color-light, 0.5);
	}
}

select,
textarea,
.input__upload {
	width: 100%;
	box-shadow: $box-shadow;
	background-color: $white;
	border: 1px solid $border-color;

	transition: border-color $animation-fast;
}

select:focus,
textarea:focus,
input:not([class*='c-button']):not([type='range']):focus {
	outline: $focus-outline;
}

input[disabled],
select[disabled],
textarea[disabled],
[class*='input__'][disabled] [class*='icon'] {
	background-color: $border-color;
}

fieldset {
	border: none !important;
	padding: 0 !important;
}

// Disable the clear behavior on search fields.
input[type='search']::-webkit-search-cancel-button {
	display: none;
}

input[type='search']::-ms-clear {
	display: none;
	width: 0;
	height: 0;
}

// -------------------------------------------------------------------
// :: GENERAL CLASSES
// -------------------------------------------------------------------

form.container {
	position: relative;

	.c-form-reset {
		font-size: rem($font-size-small);
		position: absolute;
		bottom: 0.2rem;
		right: 2rem;
		text-align: right;
		z-index: 4;

		@include respond-at($mobile) {
			top: -1.5rem;
			bottom: auto;
		}
	}
}

.c-input {
	& + .c-input {
		margin-top: 10px;
	}

	.box .visual-box,
	> input.c-input--as-textfield {
		box-shadow: $box-shadow;
		background-color: $white;
		border: 1px solid $border-color;

		transition: border-color $animation-fast;
	}

	label:not([class*='input__']) {
		font-weight: 100;
		font-size: rem($font-size);
		margin-bottom: 0.25rem;
		color: $font-color-light;
		display: block;
	}

	a {
		position: relative;
		z-index: 2;
	}

	span[type='reset'] {
		cursor: pointer;
	}
}

p + .c-input {
	margin-top: 0.75rem;
}

// -------------------------------------------------------------------
// :: LABEL AND INPUT ON 1 LINE:
// -------------------------------------------------------------------

.c-input .box {
	position: relative;
	width: 100%;
	display: table;
	z-index: 1;

	label {
		display: table-cell;
		white-space: nowrap;
		padding: 0.65rem 0 0.65rem 0.8rem;
		width: 1%;
		vertical-align: middle;
	}

	span[class*='icon'] {
		transition: all $animation-fast;
		opacity: 0;
		font-weight: 100;
		font-size: rem($font-size);
		margin-bottom: 0.25rem;
		color: $font-color-light;
		display: table-cell;
		white-space: nowrap;
		padding: 0.65rem 0.8rem;
		width: 1%;
		vertical-align: middle;
	}

	&.no-disable {
		input[disabled] {
			cursor: default;
		}
	}

	&:hover {
		span[class*='icon'] {
			opacity: 1;
		}
	}

	input {
		display: table-cell;
		white-space: normal;
		vertical-align: middle;
		width: 99%;
		background: none;
	}

	input:focus + .visual-box {
		border-color: $primary-color;
	}

	.visual-box {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: -1;
	}
}

// -------------------------------------------------------------------
// :: DECORATED INPUTS
// -------------------------------------------------------------------
// Input fields are wrapped to ease placement of
// additional (optional) icons after the input field

.c-input div {
	position: relative;
}

// Input with measuring unit

.c-input .unit {
	position: absolute;
	top: 1px;
	right: 1px;
	font-weight: bold;
	font-size: 1em;
	background-color: $background-color;
	padding: 0.35em 1.1rem 0.35em 0;
	line-height: 1.8em;
}

// Input with icon

.c-input.c-input--with-icon {
	position: relative;

	span[class*='icon-'] {
		position: absolute;
		left: 0.5rem;
		top: 50%;
		transform: translateY(-50%);
		font-size: 26px;
		color: $font-color-medium;

		+ input {
			padding-left: 3rem;
		}
	}

	input + span[class*='icon-'] {
		left: auto;
		right: 0.5rem;
	}

	span.icon-search {
		display: flex;
		font-size: 1.4rem;
	}

	// Needed to add the 1% so the close-icon would be centered in the grey circle - a little bit of foefelare but I had to, sorry

	.c-button--rounded {
		position: absolute;
		right: 1%;
		top: 8%;

		@include respond-at($tablet-wide) {
			top: 19%;
		}

		span {
			left: -1%;

			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 100%;

			font-size: 0.9rem;

			color: $white;
		}
	}
}

// Inline search with button

.c-input-search {
	position: relative;
}

.c-input-search label {
	position: absolute;
	left: -9999em;
}

.c-input-search .c-button {
	height: 100%;
	position: absolute;
	right: 0;
	top: 0;
	width: 3rem;
}

.c-input.is-success {
	select,
	textarea,
	input:not([class*='c-button']),
	.visual-box {
		border-color: $ui-green !important;
	}

	small {
		color: $ui-green;
	}
}

.c-input.is-error {
	select,
	textarea,
	input:not([class*='c-button']),
	.visual-box {
		border-color: $ui-red !important;
	}

	small {
		color: $ui-red;
	}
}

.c-input.is-warning {
	select,
	textarea,
	input:not([class*='c-button']),
	.visual-box {
		border-color: $ui-orange !important;
	}

	small {
		color: $ui-orange;
	}
}

// -------------------------------------------------------------------
// :: RANGE SLIDERS
// -------------------------------------------------------------------
// https://css-tricks.com/styling-cross-browser-compatible-range-inputs-css/

input[type='range'] {
	height: rem(24px);
	padding: 0;
	margin: 0;
	border-radius: rem(12px) !important;

	background: transparent;
	cursor: pointer;

	// Style input types on IOS
	// with webkit-appearance

	-webkit-appearance: none;
	-moz-appearance: none;
}

input[type='range']::-moz-focus-outer {
	border: none;
}

// Hide the slider on Windows

input[type='range']::-ms-track {
	width: 100%;
	background: transparent;
	border-color: transparent;
	color: transparent;
}

input[type='range']::-moz-range-track {
	width: 100%;
	background: transparent;
	border-color: transparent;
	color: transparent;
}

input[type='range']::-ms-fill-lower {
	background: transparent;
}

// Styling the knob

input[type='range']::-webkit-slider-thumb {
	width: rem(26px);
	height: rem(26px);
	border-radius: 50%;
	border: 3px solid $border-color;
	background: $primary-color;
	box-shadow: 0 0 1px $font-color-medium;
	-webkit-appearance: none;
	-moz-appearance: none;
	z-index: 2;
	position: relative;
}

input[type='range']::-moz-range-thumb {
	width: rem(26px);
	height: rem(26px);
	border-radius: 50%;
	border: 3px solid $border-color;
	background: $primary-color;
	box-shadow: 0 0 1px $font-color-medium;
	-webkit-appearance: none;
	-moz-appearance: none;
	z-index: 2;
	position: relative;
}

input[type='range']::-ms-thumb {
	width: rem(26px);
	height: rem(26px);
	border-radius: 50%;
	border: 3px solid $border-color;
	background: $primary-color;
	box-shadow: 0 0 1px $font-color-medium;
	-webkit-appearance: none;
	-moz-appearance: none;
	z-index: 2;
	position: relative;
}

// Background track
// of the slider

.c-input--range__background {
	width: 97%;
	height: rem(8px);
	position: absolute;
	top: rem(8px);
	left: 1.5%;

	background: $black-dark-blue;
	border-radius: rem(4px);
}

.c-input--range__value {
	background: $primary-color;
	width: 0;
}

// -------------------------------------------------------------------
// :: AUTOCOMPLETE
// -------------------------------------------------------------------
.c-autocomplete {
	position: relative;
	margin-bottom: 40px;

	> .c-input:first-child {
		margin-top: 0;
	}
}

div.c-autocomplete-results {
	position: absolute;
	width: 100%;
	max-height: 150px;
	overflow-y: auto;
	background: $grey-lighter;
	top: 50px;
	box-shadow: $box-shadow;
	z-index: 100;

	ul {
		padding: 0;
		margin: 0;
	}

	li {
		background: transparent;

		list-style: none;
		font-size: 0.9rem;

		padding: 0.7rem 1rem 0.6rem;

		cursor: pointer;

		transition: all $animation-fast;

		&:hover {
			background: $grey-light;
		}
	}
}

// -------------------------------------------------------------------
// :: FIELDSET
// -------------------------------------------------------------------

fieldset {
	border: none;
	border-bottom: 1px solid $border-color;
	padding: 2em;

	&:last-child {
		border-bottom: none;
	}
}

// -------------------------------------------------------------------
// :: SELECT
// -------------------------------------------------------------------
// Adjust padding-right on selects to accomodate for arrow

select {
	background-image: url('data:image/svg+xml;charset=utf8, %3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%221em%22%20viewBox%3D%220%200%20448%20512%22%3E%3C!--!%20Font%20Awesome%20Free%206.4.2%20by%20%40fontawesome%20-%20https%3A%2F%2Ffontawesome.com%20License%20-%20https%3A%2F%2Ffontawesome.com%2Flicense%20(Commercial%20License)%20Copyright%202023%20Fonticons%2C%20Inc.%20--%3E%3Cpath%20d%3D%22M201.4%20342.6c12.5%2012.5%2032.8%2012.5%2045.3%200l160-160c12.5-12.5%2012.5-32.8%200-45.3s-32.8-12.5-45.3%200L224%20274.7%2086.6%20137.4c-12.5-12.5-32.8-12.5-45.3%200s-12.5%2032.8%200%2045.3l160%20160z%22%2F%3E%3C%2Fsvg%3E');
	background-repeat: no-repeat;
	background-position: right 0.6rem center;
	appearance: none;
	min-width: 220px;
	padding: 0.6rem 2rem 0.6rem 0.6rem;

	@include respond-to($mobile) {
		min-width: 150px;
	}
}

// Add pointer cursor for selects
// Provide styling when no value has been selected

select.has-no-value {
	color: $font-color-medium;
	font-style: italic;
	font-weight: 100;
}

select[disabled].has-no-value {
	color: $font-color-medium;
}

// Decorators

.c-input select + [class*='icon'] {
	color: $font-color-light;
	font-size: $font-size-small;
	margin: 0 0.9rem;
	pointer-events: none;
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
}

.c-input select + [class*='icon']:before {
	font-size: 1.65em;
	line-height: 0;
}

.c-input select.has-no-value + [class*='icon'] {
	color: $font-color-light;
}

.c-select__horizontal {
	.c-input {
		display: grid;
		grid-auto-flow: column;
		column-gap: 15px;
		place-content: center;

		label {
			display: grid;
			place-content: center;

			span {
				height: fit-content;
			}
		}
	}
}

// -------------------------------------------------------------------
// :: SELECT MODIFIERS
// -------------------------------------------------------------------
.c-select--theme-blue {
	span.c-select--theme-blue__icon {
		color: $black;
		font-size: 0.7rem;
	}
}

.c-select--theme-blue__field {
	padding: 1rem;

	font-weight: 500;

	color: $primary-color;

	box-shadow: none;
}

.c-select--inline {
	display: inline-flex;
	align-items: center;

	@include respond-to($mobile) {
		flex-direction: column;
		align-items: flex-end;
	}

	label {
		margin-right: 20px;

		white-space: nowrap;

		@include respond-to($mobile) {
			margin-right: 0;
		}
	}
}

// -------------------------------------------------------------------
// :: SWITCH
// -------------------------------------------------------------------

.c-toggle--switch .toggle__holder {
	position: relative;
	width: rem(48px);
	height: rem(24px);
	border-radius: rem(12px);
}

.c-toggle--switch input:checked + .toggle__holder {
	border-color: $border-color;
}

// Circle button

.c-toggle--switch .toggle__holder:before {
	content: '';
	display: block;
	border-radius: 100%;
	width: rem(12px);
	height: rem(12px);
	margin: rem(5px);
	background: $border-color;
	opacity: 1;
	z-index: 3;
	position: relative;
	transition: all 0.2s ease-in;
}

.c-toggle--switch input:checked + .toggle__holder:before {
	transform: translateX(rem(23px));
	background: $primary-color;
}

// Icon decorators
.toggle__holder > span {
	width: auto;
	border: none;
	color: $font-color-medium;
	background: transparent;
	position: absolute;
	top: 0;
	transition: all 0.15s ease-in;
}

.c-toggle--switch .toggle__on {
	left: rem(8px);
	opacity: 0;
}

.c-toggle--switch .toggle__off {
	right: rem(8px);
	opacity: 1;
}

// States

.c-toggle--switch input:checked + .toggle__holder {
	.toggle__on {
		opacity: 1;
	}

	.toggle__off {
		opacity: 0;
	}
}

// -------------------------------------------------------------------
// :: TEXTAREA
// -------------------------------------------------------------------
// Resize options for textarea
// vertical/horizontal/both/none

textarea {
	resize: vertical;
}

// -------------------------------------------------------------------
// :: GENERAL RADIO & CHECKBOX CLASSES
// -------------------------------------------------------------------

[class*='input__'] {
	margin: 0.5rem 0.8rem;
	line-height: rem(20px);
	vertical-align: text-bottom;
	display: inline-block;
	position: relative;
	cursor: pointer;
}

[class*='input__']:first-child {
	margin-left: 0;
}

//[class*='input__']:not(:last-child) {
//	margin-right: 1em;
//}

// Style inputs
// and icons

[class*='input__'] input {
	cursor: pointer;
	opacity: 0 !important;
	position: absolute;
	z-index: 2;
	top: -3px;
}

[class*='input__'] input:not(.button) {
	left: 0;
}

[class*='input__'] [class*='icon'] {
	width: rem(20px);
	height: rem(20px);
	margin: 0 0.8em 0 0;
	transition: all 100ms ease-in-out;
	border: 1px solid $border-color;
	background: $background-color;
	position: relative;
	top: -1px;
	float: left;
}

//[class*='input__'] input:checked ~ [class*='icon'] {
//  border-color: $primary-color;
//}

[class*='input__'] [class*='icon']:before {
	transition: all 100ms ease-in-out;
	opacity: 0;
	font-size: 20px;
	left: -2px;
	top: -1px;
}

[class*='input__'] input:checked ~ [class*='icon']:before {
	opacity: 1;
}

// -------------------------------------------------------------------
// :: RADIO CLASSES
// -------------------------------------------------------------------

[class*='input__toggle'] .icon,
[class*='input__radio'] .icon {
	border-radius: 50%;
}

[class*='input__toggle'] .icon:before,
[class*='input__radio'] .icon:before {
	content: '';
	width: rem(10px);
	height: rem(10px);
	margin: rem(4px);
	border-radius: inherit;
	background: $primary-color;
	display: block;
}

// -------------------------------------------------------------------
// :: ICON CHECKBOX CLASSES
// -------------------------------------------------------------------

[class*='icon__checkbox'] [class*='input__'] {
	display: block;
	position: relative;
	float: right;

	width: 48px;
	height: 48px;
}

[class*='icon__checkbox'] [class*='icon'] {
	border: none;
	font-size: 3em;
	width: 48px;
	height: 48px;
	top: 0;

	&:before {
		opacity: 1;
		color: $border-color;
	}
}

[class*='icon__checkbox'] input:hover + [class*='icon']:before {
	color: $font-color;
}

[class*='icon__checkbox'] input:checked + [class*='icon']:before {
	color: $primary-color;
}

// -------------------------------------------------------------------
// :: CHECKBOX CLASSES
// -------------------------------------------------------------------

[class*='input__checkbox'] [class*='icon'] {
	color: $primary-color;
}

[class*='input__checkbox'] [class*='icon']:before {
	position: relative;
	//left: rem(1.5px);
	//top: rem(-1px);
}

// -------------------------------------------------------------------
// :: UPLOAD FIELD
// -------------------------------------------------------------------
.c-input--upload {
	margin: 0;
	padding: 0;
}

.c-input--upload input[type='file'] {
	left: 0;
	opacity: 0;
	position: absolute;
	z-index: 99;
	top: 0;

	&:hover {
		cursor: pointer;
	}
}

.c-input--upload input[type='text'] {
	opacity: 1;
}
