// -------------------------------------------------------------------
// :: DEFAULT STATE
// -------------------------------------------------------------------

// Denis: The following contains styling for both the menu in the header as the flyout menu in an overlay.
.c-menu, // -> The host class of libs/shared/ui/common/src/lib/components/menu/menu.component.ts
.c-menu-overlay {
	// -> The host class of the Angular Material overlay
	// Denis: The menu toggle is the button that is shown on mobile to toggle the primary navigation (`nav` element).
	.c-menu__toggle {
		background-color: transparent;
		padding: rem(10px) 0;
		border: none;
		line-height: rem(24px);
		font-family: $menu-item-font-family;
		font-size: rem($menu-item-font-size);
		color: $black;
	}

	// Denis: The menu items is the group of links in the primary and secondary navigation (`ul` element).
	.c-menu__items {
		padding: 0;
		list-style: none;

		// Denis: The menu item is the list item within that list (`li` element).
		.c-menu__items__item {
			// Denis: The menu link is the actual link within that list item (`a` element).
			.c-menu__items__item__link {
				display: block;
				padding-top: rem(10px);
				line-height: rem(24px);
				font-family: $menu-item-font-family;
				font-size: rem($menu-item-font-size);
				color: $black;
				text-decoration: none;

				&.is-active {
					padding-bottom: rem(5px);
					border-bottom: rem(5px) solid $primary-color;
					font-family: $menu-item-active-font-family;
					color: $menu-item-active-color;
				}

				&:not(.is-active) {
					padding-bottom: rem(10px);
				}
			}
		}

		@include respond-to($desktop) {
			display: grid;
			gap: $layout-spacing-s;

			.c-menu__items__item {
				.c-menu__items__item__link {
					width: max-content;
				}
			}
		}

		@include respond-from-to($desktop, $desktop-wide) {
			display: grid;
			grid-auto-columns: max-content;
			grid-auto-flow: column;
			gap: $layout-spacing;
		}

		@include respond-at($desktop-wide) {
			display: grid;
			grid-auto-columns: max-content;
			grid-auto-flow: column;
			gap: $layout-spacing-md;
		}
	}
}

.c-menu {
	.c-menu__items {
		&.c-menu__items--grey-background {
			background-color: $background-color-light-grey;

			.c-menu__items__item__link {
				padding-right: $layout-spacing-s;
				padding-left: $layout-spacing-s;
			}
		}
	}
}

// Denis: The following contains styling for the flyout menu in an overlay.
.c-menu-overlay {
	background-color: $white;
	top: calc($header-height * 2);
	width: 100%;
	max-height: calc(100% - ($header-height * 2));
	padding: $layout-spacing;
	overflow-y: auto;

	@include respond-at($tablet) {
		padding: 0 $layout-spacing-md;
	}
}
