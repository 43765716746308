$arrow-size: 16px;
$background: $background-color-base;
$tooltip-border: $border-color-dark-grey;
$arrow-border: $border-color-dark-grey;

// Denis: This custom theme is based on the default light-border theme:
// https://github.com/atomiks/tippyjs/blob/master/src/scss/themes/light-border.scss
.tippy-box[data-theme~='vlo'] {
	background-color: $background;
	background-clip: padding-box;
	border: 1px solid $tooltip-border;
	font-family: $font-family-sans;
	color: $font-color;
	box-shadow: $box-shadow-big;

	> .tippy-backdrop {
		background-color: $background;
	}

	> .tippy-arrow,
	> .tippy-svg-arrow {
		&::after {
			content: '';
			position: absolute;
			z-index: -1;
		}
	}

	> .tippy-arrow::after {
		border-color: transparent;
		border-style: solid;
	}

	&[data-placement^='top'] {
		> .tippy-arrow {
			&::before {
				border-top-color: $background;
			}

			&::after {
				border-top-color: $arrow-border;
				border-width: 7px 7px 0;
				top: $arrow-size + 1;
				left: 1px;
			}
		}

		> .tippy-svg-arrow {
			> svg {
				top: $arrow-size;
			}

			&::after {
				top: $arrow-size + 1;
			}
		}
	}

	&[data-placement^='bottom'] {
		> .tippy-arrow {
			&::before {
				border-bottom-color: $background;
				bottom: $arrow-size;
			}

			&::after {
				border-bottom-color: $arrow-border;
				border-width: 0 7px 7px;
				bottom: $arrow-size + 1;
				left: 1px;
			}
		}

		> .tippy-svg-arrow {
			> svg {
				bottom: $arrow-size;
			}

			&::after {
				bottom: $arrow-size + 1;
			}
		}
	}

	&[data-placement^='left'] {
		> .tippy-arrow {
			&::before {
				border-left-color: $background;
			}

			&::after {
				border-left-color: $arrow-border;
				border-width: 7px 0 7px 7px;
				left: $arrow-size + 1;
				top: 1px;
			}
		}

		> .tippy-svg-arrow {
			> svg {
				left: 11px;
			}

			&::after {
				left: 12px;
			}
		}
	}

	&[data-placement^='right'] {
		> .tippy-arrow {
			&::before {
				border-right-color: $background;
				right: $arrow-size;
			}

			&::after {
				border-width: 7px 7px 7px 0;
				right: $arrow-size + 1;
				top: 1px;
				border-right-color: $arrow-border;
			}
		}

		> .tippy-svg-arrow {
			> svg {
				right: 11px;
			}

			&::after {
				right: 12px;
			}
		}
	}

	> .tippy-svg-arrow {
		fill: white;

		&::after {
			background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMCA2czEuNzk2LS4wMTMgNC42Ny0zLjYxNUM1Ljg1MS45IDYuOTMuMDA2IDggMGMxLjA3LS4wMDYgMi4xNDguODg3IDMuMzQzIDIuMzg1QzE0LjIzMyA2LjAwNSAxNiA2IDE2IDZIMHoiIGZpbGw9InJnYmEoMCwgOCwgMTYsIDAuMikiIC8+PC9zdmc+);
			background-size: $arrow-size 6px;
			width: $arrow-size;
			height: 6px;
		}
	}
}
