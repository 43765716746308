// -----------------------------------------------------------------------------
// :: Settings
// -----------------------------------------------------------------------------
$modal-button-color: rgba($white, 0.33);

// -----------------------------------------------------------------------------
// :: Modal
// -----------------------------------------------------------------------------
.c-modal {
	position: fixed;
	top: 43px;
	left: 0;
	z-index: 9999;

	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: calc(100vh - $header-menu-height);
	overflow: auto;

	background: $white;

	transition: all $animation-fast;

	@include respond-at($mobile) {
		position: relative;
		top: 50%;
		left: unset;

		display: block;
		width: 100%;
		max-width: 500px;
		min-height: unset;
		margin-right: auto;
		margin-left: auto;

		transform: translateY(-50%);
	}
}

// -----------------------------------------------------------------------------
// :: Elements
// -----------------------------------------------------------------------------
.c-modal__header {
	display: inline-flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 18px 20px;

	border-bottom: 1px solid $grey-light;
}

.c-modal__title {
	display: inline-block;
}

.c-modal__body {
	flex: 1;
	height: 100%;
	overflow: auto;
	margin: 25px 20px 20px;

	@include respond-at($mobile) {
		max-height: 400px;
	}

	.c-input:last-child {
		margin-bottom: 0;

		small {
			padding-bottom: 0;
		}
	}
}

.c-modal__footer {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: 20px;

	text-align: center;

	border-top: 1px solid $grey-light;
}

.c-modal__action {
	& + .c-modal__action {
		margin-left: 20px;
	}
}

// -----------------------------------------------------------------------------
// :: Modifiers
// -----------------------------------------------------------------------------
.c-modal--with-icon {
	[class*='icon-'] {
		font-size: 3em;
		color: $primary-color;
	}

	.c-modal__body {
		text-align: center;
	}
}

.c-modal--wide {
	max-width: 50rem;
}

.c-modal--small {
	max-width: 380px;
	padding: 60px 30px;
	position: relative;

	.c-modal__close {
		position: absolute;
		right: 20px;
		top: 20px;
	}

	.c-modal__header {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		padding: 0 0 10px;

		border-bottom: none;
	}

	.c-modal__body {
		margin: 10px 0 0;

		text-align: center;
	}
}
