// sass-lint:disable-all
// -------------------------------------------------------------------
// :: CONVERT PX TO REM
// -------------------------------------------------------------------
// Important: rem-units are not supported on <IE9
// and we don't provide a fallback
//
// - http://caniuse.com/#search=rem

@use 'sass:math';

@function rem($_value) {
	@return #{math.div($_value, 16px)}rem;
}

@function px($value) {
	$integer: math.div($value, $value * 0 + 1) * 10px;

	@return round($integer);
}

// -------------------------------------------------------------------
// :: GET FONT
// -------------------------------------------------------------------

@mixin get-font($_name, $_url, $_weight: normal, $_style: normal) {
	@font-face {
		font-family: $_name;
		src: url($_url + '.eot?v=4'); /* IE9 Compat Modes */
		src:
			url($_url + '.eot?v=4#iefix') format('embedded-opentype'),
			/* IE6-IE8 */ //url($_url +".woff2?v=4") format("woff2"), /* Super Modern Browsers */
			url($_url + '.woff?v=4') format('woff'),
			/* Pretty Modern Browsers */ url($_url + '.ttf?v=4') format('truetype'),
			/* Safari, Android, iOS */ url($_url + '.svg?v=4#icon') format('svg'); /* Legacy iOS */
		font-weight: $_weight;
		font-style: $_style;
		font-display: swap;
	}
}

// -------------------------------------------------------------------
// :: FR
// -------------------------------------------------------------------
// This function resolves the bug with 1fr in grid.
// If the content is bigger than the fraction, it will push the grid outside its boundaries.
// By setting min on 0 instead of the default auto; this is prevented.
@function fr($fraction) {
	@return minmax(0, #{$fraction}fr);
}
