.c-button {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	padding: rem(10px);

	background-color: $button-primary;
	border: rem(2px) solid $button-primary;
	color: $button-text;

	font-family: $font-family-primary-medium;
	font-size: rem(18px);
	line-height: 1.2rem;
	text-decoration: none;

	word-break: break-word; // This is deprecated as stated here: https://developer.mozilla.org/en-US/docs/Web/CSS/word-break
	overflow-wrap: anywhere; // This is the replacement, but is not yet fully supported as stated here: https://developer.mozilla.org/en-US/docs/Web/CSS/overflow-wrap

	transition:
		background-color 0.2s ease-in-out,
		border-color 0.2s ease-in-out;

	cursor: pointer;

	&:focus-visible {
		outline: $focus-outline;
		outline-offset: 2px;
	}

	&:hover {
		background-color: $button-primary-hover;
		border-color: $button-primary-hover;
		color: $button-text-hover;
	}

	// libs/shared/ui/common/src/lib/components/button/button.component.scss
	&.is-disabled {
		color: $text-color-blue-grey-light;
		background-color: $grey-light;
		border-color: $grey-light;

		cursor: not-allowed;
	}

	@include respond-at($desktop) {
		padding: rem(10px) rem(24px);
	}
}

.c-button__icon-after {
	margin-left: rem(8px);
}

.c-button__icon-before {
	margin-right: rem(8px);
}

.c-button__icon-after,
.c-button__icon-before,
.c-button__icon {
	font-size: rem(20px);
}

// -------------------------------------------------------------------
// :: MODIFIERS
// -------------------------------------------------------------------
.c-button--outline {
	color: $button-text-outline;
	background-color: transparent;

	&:hover {
		color: $white;
		border-color: $button-primary-hover;
	}

	&.c-button--inverted {
		color: $white;
		border-color: $white;

		&:hover {
			color: $button-primary;
			background-color: $white;
		}
	}
}

// Denis: Duplicate from libs/shared/ui/common/src/lib/components/button/button.component.scss
// Duplicated to make it work for button-style links
.c-button--blue-outline-solid {
	background-color: $white;
	border-color: $blue-vlaanderen;
	color: $blue-vlaanderen;

	&:hover {
		border-color: $blue-dark;
		background-color: $blue-dark;

		color: white;
	}
}

.c-button--inverted {
	color: $button-primary;
	border-color: $white;
	background-color: $white;
}

.c-button--dark {
	color: $black;
	background-color: $white;
	border-color: $black;

	&:hover {
		color: $white;
		background-color: $black;
		border-color: $black;
	}
}

.c-button--orange {
	color: $white;
	border-color: $orange;
	background-color: $orange;

	&:hover {
		background-color: $orange-dark;
		border-color: $orange-dark;
	}
}

.c-button--grey {
	color: $white;
	border-color: $grey-darker;
	background-color: $grey-darker;

	&:hover {
		background-color: $grey-darkest;
		border-color: $grey-darkest;
	}
}

.c-button--light-grey {
	color: $grey-darkest;
	border-color: $grey-medium;
	background-color: $grey-medium;

	&:hover:not(:disabled) {
		color: $grey-darkest;
		border-color: $grey-85;
		background-color: $grey-85;
	}

	&:disabled {
		opacity: 0.5;
		color: $grey-darkest;
		border-color: $grey-medium;
		background-color: $grey-medium;

		cursor: not-allowed;
	}
}

.c-button--red {
	background-color: $ui-red;
	border-color: $ui-red;

	&.is-disabled {
		// rgba($ui-red, 0.3)
		background-color: rgba(247, 212, 211, 1) !important;
	}
}

.c-button--blue {
	background-color: $blue-vlaanderen;
	border-color: $blue-vlaanderen;
	color: white;

	&:hover {
		border-color: $blue-dark;
		background-color: $blue-dark;
	}
}

.c-button--blue-outline {
	background-color: transparent;
	border-color: $blue-vlaanderen;
	color: $blue-vlaanderen;

	&:hover {
		border-color: $blue-dark;
		background-color: $blue-dark;

		color: white;
	}
}

.c-button--dark-text {
	color: $black;
}

.c-button--small {
	padding: rem(5px);

	@include respond-at($desktop) {
		padding: rem(5px) rem(12px);
	}
}

.c-button--search {
	width: 100%;

	@include respond-at($desktop) {
		display: block;
		padding-left: 4rem;
		padding-right: 4rem;
		text-transform: lowercase;
		margin-left: 0.6rem;
		width: auto;

		&::first-letter {
			text-transform: uppercase;
		}
	}
}

.c-button--rounded {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 1.8rem;
	width: 1.8rem;

	padding: 0;

	border: none;
	border-radius: rem(50px);

	[class*='icon'] {
		line-height: 1;
		display: block;
	}
}

.c-button__trash {
	z-index: 2;
	background-color: transparent;
	border: none;
	color: $ui-red;

	&.bg-light {
		background-color: $grey-light;
	}
}

.c-button--link {
	color: $link-color;
	text-decoration: $link-underline;

	&:hover {
		color: $link-color-dark;
	}
}

.c-button--full-width {
	width: 100%;
}

.c-button--icon-left {
	fa-icon {
		margin-right: $layout-spacing-xs;
	}
}

.c-button--icon-right {
	fa-icon {
		margin-left: $layout-spacing-xs;
	}
}

.c-button--icon-only {
	display: grid;
	justify-content: center;
	align-items: center;
	width: 42px;
	height: 42px;
	padding: 0;
}

.c-button--action {
	text-align: center;
}
