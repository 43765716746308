.c-vloket-form {
	select {
		// Denis: selects have a min-width of 220px. Important is needed to overwrite.
		min-width: auto;
	}

	input,
	select,
	textarea {
		// Denis: prevent the line-height of an input to be set to inherit by normalize.
		// line-height does not work on select, causing an irregular effect when both
		// select and input are put next to each other with line-height set to inherit.
		line-height: initial;
	}

	fieldset {
		margin: 0;
	}

	.c-vloket-form__fieldset {
		margin: rem(10px) 0 rem(30px);

		&__subset--no-spacing {
			margin: 0;
		}

		&__subset--2-col {
			// Denis: make a cjm-checkbox take up the full width in this layout.
			.c-filter__checkbox {
				width: 100%;
			}

			@include respond-at($tablet) {
				column-count: 2;
			}
		}

		&__subset--3-col {
			// Denis: make a cjm-checkbox take up the full width in this layout.
			.c-filter__checkbox {
				width: 100%;
			}

			@include respond-at($tablet) {
				column-count: 3;
			}
		}

		&__subset--split-equal {
			display: grid;

			@include respond-at($tablet) {
				grid-template-areas: 'left right';
				grid-template-columns: fr(1) fr(1);
				column-gap: $layout-spacing;
			}

			&:last-child {
				.c-input {
					margin-bottom: 0;
				}
			}
		}

		&__extend-bottom-spacing {
			margin-bottom: $layout-spacing;
		}
	}

	&__actions {
		display: flex;
		flex-wrap: wrap;
		column-gap: rem(30px);
		row-gap: rem(10px);
		align-items: center;

		.c-button {
			flex-grow: 1;
			flex-shrink: 1;
		}
	}

	.button-with-info {
		display: grid;
		grid-template-areas: 'button info';
		grid-template-columns: auto 1fr;
	}
}

.v-loket {
	// Denis: overwrite for _input.scss
	.c-input {
		margin-top: 0;
		margin-bottom: $layout-spacing;

		& + .c-input {
			margin-top: 0;
		}

		.c-input {
			margin-bottom: 0;
		}

		label,
		label:not([class*='input__']) {
			margin-bottom: rem(10px);
			font-family: $font-family-medium;
			font-size: rem($font-size);
			max-width: max-content;

			small {
				padding: 0;
				font-family: $font-family-sans;
				color: $text-color-blue-grey;
			}
		}

		input.c-input--as-textfield,
		select.c-input--as-textfield,
		textarea {
			border: $border-input;
			box-shadow: none;
			margin: 0;

			&::placeholder {
				color: $text-color-blue-grey;
				opacity: 1;
			}

			&:disabled {
				background-color: $background-color-light-grey;
				border-color: $border-color-dark;
			}
		}

		&--is-disabled {
			.c-input__label {
				color: $text-color-blue-grey;
			}
		}

		&--with-icon-button {
			position: relative;

			.c-input__icon-button {
				background-color: white;
				position: absolute;
				top: rem(12px);
				right: rem(12px);
				margin: 0;
				border: none;
				border-radius: 50%;
				padding: 0;
			}
		}

		&--prevent-spacing {
			margin-bottom: 0;
		}
	}

	// Field hints that can be shown above the field.
	.c-input__hint {
		margin: 0 0 $layout-spacing-s;
		font-size: rem(16px);
		color: $text-color-blue-grey;
	}

	// Field descriptions that can be shown below the field.
	.c-input__description {
		margin: $layout-spacing-xs 0 0;
		font-size: rem(16px);
		color: $text-color-blue-grey;

		&:empty {
			margin: 0;
		}

		&.is-success {
			@extend .is-success;
		}
		&.is-warning {
			@extend .is-warning;
		}
		&.is-error {
			@extend .is-error;
		}
	}

	.c-filter__checkbox {
		margin-bottom: $layout-spacing-xs;

		.c-filter__label {
			font-size: rem($font-size);
		}
	}

	// Denis: hide the controls on input:number to avoid Angular not picking up on the value
	.c-input--as-textfield[type='number'] {
		-webkit-appearance: textfield;
		-moz-appearance: textfield;
		appearance: textfield;
	}
}
