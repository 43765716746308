@mixin raster-bottom {
	content: '';
	display: block;
	width: 100%;
	height: 9px;
	position: absolute;
	bottom: 0;
	left: 0;
	background-image: linear-gradient(
		45deg,
		$primary-color 25%,
		$white 25%,
		$white 50%,
		$primary-color 50%,
		$primary-color 75%,
		$white 75%,
		$white 100%
	);
	background-size: 5.66px 5.66px;
}

@mixin raster-top {
	content: '';
	display: block;
	width: 100%;
	height: 9px;
	position: absolute;
	top: 0;
	left: 0;
	background-image: linear-gradient(
		45deg,
		$primary-color 25%,
		$white 25%,
		$white 50%,
		$primary-color 50%,
		$primary-color 75%,
		$white 75%,
		$white 100%
	);
	background-size: 5.66px 5.66px;
}

@mixin raster-top-grey {
	content: '';
	display: block;
	width: 100%;
	height: 9px;
	position: absolute;
	top: 0;
	left: 0;
	background-image: linear-gradient(
		45deg,
		$grey-83 25%,
		$white 25%,
		$white 50%,
		$grey-83 50%,
		$grey-83 75%,
		$white 75%,
		$white 100%
	);
	background-size: 5.66px 5.66px;
}

@mixin raster-bottom-grey {
	content: '';
	display: block;
	width: 100%;
	height: 9px;
	position: absolute;
	bottom: 0;
	left: 0;
	background-image: linear-gradient(
		45deg,
		$grey-83 25%,
		$white 25%,
		$white 50%,
		$grey-83 50%,
		$grey-83 75%,
		$white 75%,
		$white 100%
	);
	background-size: 5.66px 5.66px;
}
