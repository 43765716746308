// -------------------------------------------------------------------
// :: IMAGES
// -------------------------------------------------------------------
// Images are set out to be fluid by default
// Inspired by the Inuit.css framework
//
// - https://github.com/csswizardry/inuit.css/blob/master/base/_images.scss
// - http://demosthenes.info/blog/586/CSS-Fluid-Image-Techniques-for-Responsive-Site-Design
// - http://www.stucox.com/blog/dont-squash-me-using-min-width-on-fluid-images

img {
	outline: 0;
	border: none;
}

img:not([width]):not([height]):not([class*='leaflet-']) {
	max-width: 100%;
}

img[width],
img[height] {
	vertical-align: top;
}

svg:not(:root) {
	overflow: hidden;
}

// -------------------------------------------------------------------
// :: CONTEXTUAL IMAGES
// -------------------------------------------------------------------

figure {
	margin: 0;
	text-align: center;
	display: block;
}

figure img {
	display: block;
}

// -------------------------------------------------------------------
// :: OVERLAYS
// -------------------------------------------------------------------
// Mostly used in headers, heroes and/or mastheads
// Image overlays should have an opacity between 20-60%
// depending on context, for this we use font-color values

.u-has-overlay {
	position: relative;
	color: $white;
	background-position: center;
	background-size: cover;

	&::before {
		content: '';
		width: 100%;
		height: 100%;
		background-color: $black;
		opacity: 0.5;
		position: absolute;
		left: 0;
		top: 0;

		transition: opacity $animation-medium;
	}

	&.light {
		&::before {
			opacity: 0.2;
		}

		&.is-active {
			&::before {
				opacity: 0.4;
			}
		}
	}

	&.is-active {
		&::before {
			z-index: 10;
		}
	}
}

.u-has-overlay > * {
	position: relative;
	z-index: 1;
}
