// -------------------------------------------------------------------
// :: SECTIONS
// -------------------------------------------------------------------

section {
	padding-top: 2.5rem;
	padding-bottom: 2.5rem;
}

.c-section--blue {
	background-color: $primary-color;

	p,
	h1,
	h2,
	h3,
	h4,
	h5,
	ul,
	ol {
		color: $white;
	}
}

.c-section--blue-light {
	background-color: $primary-color-light;
}

.c-section--grey {
	background-color: $grey-lightest;
}

.c-section-no-padding-top {
	padding-top: 0;
}
