// TODO: check breakpoints for the filters (Denis)
.c-filter {
	&.is-open {
		.c-filter__container {
			@include respond-to($desktop-wide) {
				transform: translateX(0);

				pointer-events: all;
				visibility: visible;
			}
		}

		.c-filter__filters {
			@include respond-to($desktop-wide) {
				// Wouter: different overflow on both axes (not unset) with one set to visible
				// makes the latter behave as 'auto', which displays a scroll
				overflow-y: scroll;
				overflow-x: hidden;
			}
		}
	}
}

.c-filter__button {
	width: 100%;

	@include respond-at($desktop-wide) {
		display: none;
	}
}

.c-filter__container {
	@include respond-at($desktop-wide) {
		padding: rem(20px);
		background-color: $filter-container-background-lg;

		border: rem(1px) solid $border-color-grey;
	}

	@include respond-to($desktop-wide) {
		position: fixed;
		top: 0;
		left: 0;
		// Denis: The material CDK overlay container has a z-index of 1000, set this 1 lower
		z-index: $z-index-sky - 1;

		width: 100%;
		height: 100%;
		padding: 0 rem(20px);

		background-color: $filter-container-background-sm;

		transform: translateX(-100%);
		transition: transform 0.2s ease-in-out;

		pointer-events: none;
		visibility: hidden;
	}
}

.c-filter__close {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: rem(20px);

	font-size: rem(24px);

	appearance: none;
	border: none;
	background-color: transparent;

	@include respond-at($desktop-wide) {
		display: none;
	}
}

.c-filter__filters {
	overflow: visible;

	.u-reset-list {
	}
}

.c-filter__filters-list {
	@include respond-to($desktop-wide) {
		padding-top: rem(20px);
		padding-bottom: rem(110px);
	}
}

.c-filter__results-button {
	position: fixed;
	bottom: rem(20px);

	width: calc(100% - #{rem(40px)});

	@include respond-at($desktop-wide) {
		display: none;
	}
}

.c-filter__title {
	padding-bottom: rem(8px);
	margin-bottom: rem(16px);

	font-family: $filter-font-family;
	font-size: rem($filter-font-size);
	line-height: rem(22px);

	border-bottom: rem(1px) solid $filter-title-border;
}

.c-filter__checkbox {
	display: inline-flex;
	align-items: $checkbox-alignement;
}

.c-filter__field {
	position: relative;

	display: block;
	flex-shrink: 0;
	width: rem(20px);
	height: rem(20px);
}

.c-filter__input {
	width: rem($checkbox-size);
	height: rem($checkbox-size);
	min-height: auto;
	margin: 0;

	border: $border-input;
	background-color: $checkbox-background;
	appearance: none;
	outline: none;

	transition:
		background-color 0.2s,
		border-color 0.2s;

	cursor: pointer;

	&:focus,
	&:active {
		border-color: $checkbox-border-checked !important;
	}

	&:checked {
		border-color: $checkbox-border-checked;
		background-color: rgba($checkbox-background-checked, 0.2);

		& + .c-filter__icon {
			transform: translate3d(-50%, -50%, 0) scale(1);
		}
	}

	&:focus,
	&:checked {
		@extend .u-outline;
	}
}

.c-filter__icon {
	position: absolute;
	top: $checkbox-top;
	left: $checkbox-left;

	font-size: rem($checkbox-icon-size);

	color: $checkbox-icon;

	transform: translate3d(-50%, -50%, 0) scale(0);

	pointer-events: none;
}

.c-filter__label {
	margin-left: rem(16px);

	font-family: $font-family-primary;
	font-size: rem($filter-checkbox-font-size);
	line-height: rem(24px);

	user-select: none;
}

.c-filter__item {
	& + .c-filter__item {
		margin-top: rem(8px);
	}
}

.c-filter__label-count {
	color: $filter-label-count;
}
